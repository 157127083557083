import { useUser } from '../../hook/user.hook';
import { useInvigilate } from '../../invigilate/invigilate.hook';
import { useWindow } from '../../util/window-helper';
import TestDPage from './test.d';
import TestMPage from './test.m';
import { useEffect } from 'react';

const TestPage = () => {
  const { isMobile } = useWindow();

  const { fetchUser } = useUser();

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {isMobile && <TestMPage />}
      {!isMobile && <TestDPage />}
    </>
  );
};

export default TestPage;

import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useWindow } from '../../../util/window-helper';
import { CKTextView } from '../../../view/ck-text.view';
import { Question } from '../../../response/test.response';
import { memo } from 'react';

export interface QuestionComponentProps {
  question: Question;
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({ question }) => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column question--h">
      <div className="pt-2 question--area">
        <CKTextView initData={question.description.en} />
      </div>
      {!isMobile && (
        <div className="mt-auto my-4 d-flex align-items-center text-end">
          <div className="ms-auto">
            <span>{t(I18nKey.POSITIVE_MARKING_TEXT)}:</span>
            <span className="font-weight-600 ms-1">
              +{question.marking.positive}
            </span>
          </div>
          <div className="ms-2">
            <span className="border-start border-dimgray ps-2">
              {t(I18nKey.NEGATIVE_MARKETING_TEXT)}:
            </span>
            <span className="font-weight-600 ms-1 text-danger">
              {question.marking.negative}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(QuestionComponent);

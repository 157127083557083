export const secondsToHMS = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toFixed(0).toString().padStart(2, '0');
  const formattedMinutes = minutes.toFixed(0).toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds
    .toFixed(0)
    .toString()
    .padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

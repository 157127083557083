import { StartTestReq } from '../request/test.request';
import { StartTestRes, TestRes } from '../response/test.response';
import httpClient from '../util/http-client';

export const TestService = {
  getTestById: async (id: string): Promise<(TestRes | never)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get(`/test/${id}`);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  startTestById: async (id: string): Promise<(StartTestRes | never)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/start-test`, {
        id: id,
      } as StartTestReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};

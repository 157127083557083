import { useApp } from '../../../hook/app.hook';
import { secondsToHMS } from '../../../util/timer.util';

const RemainingTimeCompponent = () => {
  const { testRes } = useApp();

  return (
    <>
      <b className="ms-1">
        {secondsToHMS(testRes.duration - testRes.timeTaken)}
      </b>
    </>
  );
};

export default RemainingTimeCompponent;

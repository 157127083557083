import { useEffect, useState } from 'react';

export const useFullScreen = () => {
  const [fullScreen, setFullScreen] = useState(0);

  const enterFullscreen = () => {
    const elem: any = document.documentElement as any;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if ((elem as any).webkitRequestFullscreen) {
      (elem as any).webkitRequestFullscreen(
        (Element as any).ALLOW_KEYBOARD_INPUT,
      );
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    const _document = document as any;
    if (_document.exitFullscreen) {
      _document.exitFullscreen();
    } else if (_document.mozCancelFullScreen) {
      _document.mozCancelFullScreen();
    } else if (_document.webkitExitFullscreen) {
      _document.webkitExitFullscreen();
    } else if (_document.msExitFullscreen) {
      _document.msExitFullscreen();
    }
  };

  useEffect(() => {
    if (fullScreen !== 0) {
      const _document = document as any;
      if (
        !_document.fullscreenElement &&
        !_document.mozFullScreenElement &&
        !_document.webkitFullscreenElement &&
        !_document.msFullscreenElement
      ) {
        enterFullscreen();
      } else if (_document.exitFullscreen) {
        exitFullscreen();
      }
    }
  }, [fullScreen]);

  return { fullScreen, setFullScreen };
};

import { memo, useState } from 'react';
import { Dropdown, ProgressBar } from 'react-bootstrap';
import { Question, Section } from '../../../response/test.response';
import { useApp } from '../../../hook/app.hook';

const SectionPickerComponent = () => {
  const { testRes, updateActiveSection } = useApp();

  const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);

  const selectSectionDropDown = (section: Section) => {
    updateActiveSection(section.id);
    handleDropDownToggle(false);
  };

  const handleDropDownToggle = (isOpen: boolean) => {
    setIsSectionDropdownOpen(isOpen);
  };

  return (
    <>
      <Dropdown
        show={isSectionDropdownOpen}
        className="w-75"
        onToggle={handleDropDownToggle}
      >
        <Dropdown.Toggle
          variant="light"
          id="section-dropdown"
          className={`${
            isSectionDropdownOpen
              ? 'border-primary section-drop--btn--open bg-light'
              : 'border-levenderblue'
          } font-weight-500 rounded ft-14 w-100 text-start section-drop--btn d-flex align-items-center`}
        >
          {
            testRes.sectionList.filter((section: Section) => section.active)[0]
              .name.en
          }
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 border-0 shadow">
          <ul className="list-style-none p-0 m-0 dropdown-section-menu overflow-auto">
            {testRes.sectionList.map((section: Section, index: number) => {
              let totalCount = section.questionList.length;
              let attemptedCount = section.questionList.filter(
                (question: Question) => question.attempted,
              ).length;

              const progress = (attemptedCount * 100) / totalCount;
              return (
                <li
                  onClick={() => {
                    selectSectionDropDown(section);
                  }}
                  className="px-2 pb-3 border-bottom section--ques-list"
                >
                  <div className="d-flex align-items-center ft-14">
                    <span className="section-list-badge bg-primary text-white p-2 d-flex align-items-center justify-content-center rounded">
                      {index + 1}
                    </span>

                    <div className="ms-3 w-100 d-flex">
                      <div className="d-flex flex-column w-100">
                        <div className="font-weight-600">{section.name.en}</div>
                        <div className="text-nowrap d-flex align-items-center small mt-1 text-dimgray">
                          {progress.toFixed(0)}% Done
                          <ProgressBar
                            variant="primary"
                            now={progress}
                            className="custom-progress--bar w-100 ms-2"
                          />
                        </div>
                      </div>
                      <div className="ml-2"></div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default memo(SectionPickerComponent);

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { ThemeType } from './theme.type';
import { lightTheme } from './light-theme';
import { darkTheme } from './dark-theme';
import { monoTheme } from './mono-theme';

const ThemeMap = {
  light: lightTheme,
  dark: darkTheme,
  mono: monoTheme,
};

const ThemeContext = createContext<{
  themeType: ThemeType;
  theme: any;
  setTheme: React.Dispatch<React.SetStateAction<ThemeType>>;
}>({
  themeType: ThemeType.DARK,
  theme: {},
  setTheme: () => {},
});

const useTheme = () => useContext(ThemeContext);

const AppThemeProvider = (props: PropsWithChildren) => {
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.MONO);
  let defaultTheme = ThemeMap[themeType].primary;

  useEffect(() => {
    const storedThemeType = localStorage.getItem('themeType');
    if (
      storedThemeType &&
      (storedThemeType === ThemeType.LIGHT ||
        storedThemeType === ThemeType.DARK ||
        storedThemeType === ThemeType.MONO)
    ) {
      setThemeType(storedThemeType);
    }

    if (themeType === ThemeType.LIGHT) {
      (document as any).querySelector('body').classList.add('light-theme');
    } else if (themeType === ThemeType.DARK) {
      (document as any).querySelector('body').classList.add('dark-theme');
    } else {
      (document as any).querySelector('body').classList.add('mono-theme');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('themeType', themeType);

    (document as any)
      .querySelector('body')
      .style.setProperty('background-color', defaultTheme, 'important');
    (document as any).querySelector('body').style.background =
      ThemeMap[themeType].primary;
  }, [themeType]);

  return (
    <ThemeContext.Provider
      value={{
        themeType: themeType,
        theme: ThemeMap[themeType],
        setTheme: setThemeType,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export { AppThemeProvider, useTheme };

import { t } from 'i18next';
import { Col, Row, Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';

const CarouselComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Col lg={7} md={12} className="pe-lg-5 ps-lg-0 px-2">
        <div className="left-slider-wraper me-lg-5 mx-2">
          <Row className="bg-white left-slider-wraper me-2 ms-lg-0 ms-2 d-flex py-lg-4 pb-4">
            <Col lg={2} md={12} className="d-flex align-items-center px-0">
              <img
                src="/images/pins.svg"
                className="d-none d-lg-block"
                height="480"
                alt=""
              />
              <img
                src="/images/pins-top.svg"
                className="d-lg-none d-block mx-auto"
                width="300"
                alt=""
              />
            </Col>

            <Col lg={10} md={12} className="d-flex flex-column">
              <div className="d-flex mt-lg-2 mt-4">
                <div className="ms-lg-5 px-3 text-black mt-2">
                  <h5 className="font-weight-300 m-0">{t(I18nKey.HI_TEXT)}</h5>
                  <h5 className="font-weight-300 m-0">
                    {t(I18nKey.WELCOME_TEXT)}
                  </h5>
                  <h4 className="m-0">SAISSY</h4>
                </div>
                <div className="ms-auto">
                  <div className="bg-primary rounded-25 px-2 py-lg-0 py-2 d-flex align-items-center">
                    <img src="/images/phone.svg" height="30" alt="" />
                    <div className="d-lg-flex d-none flex-column py-1 ps-3 pe-4 small">
                      <span className="text-gainsboro">
                        {t(I18nKey.HELPLINE_NO_TEXT)}
                      </span>
                      <span className="text-white">+91 99876 54321</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <Carousel
                  data-bs-theme="dark"
                  className="py-lg-5 pt-1 pb-5 login-carousel"
                  interval={null}
                >
                  <Carousel.Item>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <div>
                        <img
                          height="300"
                          className="d-block w-100"
                          src="/images/slider1.svg"
                          alt="First slide"
                        />
                      </div>
                      <div className="h-90-px px-lg-5 px-2 mx-lg-5">
                        {t(I18nKey.SLIDE_ONE_TEXT)}
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <div>
                        <img
                          height="300"
                          className="d-block w-100"
                          src="/images/slider2.svg"
                          alt="First slide"
                        />
                      </div>
                      <div className="h-90-px px-lg-5 px-2 mx-lg-5">
                        {t(I18nKey.SLIDE_TWO_TEXT)}
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <div>
                        <img
                          height="300"
                          className="d-block w-100"
                          src="/images/slider3.svg"
                          alt="First slide"
                        />
                      </div>
                      <div className="h-90-px px-lg-5 px-2 mx-lg-5">
                        {t(I18nKey.SLIDE_THREE_TEXT)}
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default CarouselComponent;

import { Badge, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { PreTestWindowEnum } from '../prepare.page';

const MenuComponent = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <ul className="list-style-none d-flex align-items-center justify-content-center p-0">
        <li className="d-flex align-items-center">
          <Badge
            pill
            bg="white"
            className={`ft-14 px-4 py-2 text-primary me-2 font-weight-600 bg-levenderblue`}
          >
            {t(I18nKey.READY_TEXT)}
          </Badge>
          <span className="step--progressbar">
            <ProgressBar
              variant="levenderblue"
              now={60}
              className="h-100 w-100"
            />
          </span>
        </li>
        {/* <li className="d-flex align-items-center">
                    <Badge pill bg="white" className="ft-14 px-4 py-2 text-dimgray me-2 font-weight-normal">
                        {t(I18nKey.SET_TEXT)}
                    </Badge>
                    <span className="step--progressbar">
                        <ProgressBar variant="levenderblue" now={0} className="h-100 w-100" />
                    </span>
                </li> */}
        <li className="d-flex align-items-center">
          <Badge
            pill
            bg="white"
            className={`ft-14 px-4 py-2 text-dimgray ${
              props.activeTab == PreTestWindowEnum.SECTIONQUESTIONLIST
                ? 'font-weight-600 bg-levenderblue'
                : 'font-weight-normal'
            }`}
          >
            {t(I18nKey.GO_TEXT)}
          </Badge>
        </li>
      </ul>
    </>
  );
};

export default MenuComponent;

import { Form } from 'react-bootstrap';
import { useApp } from '../../../../hook/app.hook';
import { Section, Question, Option } from '../../../../response/test.response';
import { CKTextView } from '../../../../view/ck-text.view';

const SingleChoiceComponent = () => {
  const { testRes, saveQuestionResponse } = useApp();

  const activeSection = testRes.sectionList.filter(
    (section: Section) => section.active,
  )[0];
  const question = activeSection.questionList.filter(
    (question: Question, index: number) => {
      return question.active;
    },
  )[0];

  return (
    <div>
      {question.optionList.map((option: Option, index: number) => {
        return (
          <Form.Check
            type="radio"
            id={`question-${question.id}-${option.id}-${activeSection.id}`}
            label={<CKTextView initData={option.value.en} />}
            name={question.id}
            value={option.id}
            defaultChecked={option.selected}
            checked={option.selected}
            className="mb-3 bg-white d-flex align-items-center p-0 mcq-inputs"
            onChange={(e: any) => {
              saveQuestionResponse(question.id, null, [option.id]);
            }}
          />
        );
      })}
    </div>
  );
};

export default SingleChoiceComponent;

import { Button } from 'react-bootstrap';
import { useApp } from '../../../hook/app.hook';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { useWindow } from '../../../util/window-helper';
import { memo } from 'react';
import { Question, Section } from '../../../response/test.response';

const PreviousNextComponent = () => {
  const { t } = useTranslation();
  const { testRes, moveNext, movePrevious } = useApp();

  const { isMobile } = useWindow();

  const section = testRes.sectionList.filter(
    (section: Section) => section.active,
  )[0];

  const activeQuestionIndex = section.questionList.filter(
    (question: Question) => question.active,
  )[0].index;

  return (
    <>
      {isMobile ? (
        <div className="d-flex align-items-center justify-content-between py-2 px-3 w-100">
          <Button
            onClick={movePrevious}
            variant="outline-primary"
            disabled={activeQuestionIndex === 1}
            className="ft-14 py-2 px-3 d-flex align-items-center font-weight-600 border-levenderblue rounded-25 previous-qs-m--btn"
          >
            <img src="/images/left-arrow.svg" className="me-2" alt="" />
            {t(I18nKey.PREVIOUS_TEXT)}
          </Button>
          <Button
            onClick={moveNext}
            disabled={activeQuestionIndex === section.questionList.length}
            variant="outline-primary"
            className="ft-14 py-2 px-3 d-flex align-items-center font-weight-600 border-levenderblue rounded-25 next-qs-m--btn"
          >
            {t(I18nKey.NEXT_TEXT)}
            <img src="/images/end-arrow.svg" className="ms-3" alt="" />
          </Button>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end prev-next-q-s--wrapper">
          <Button
            disabled={activeQuestionIndex === 1}
            onClick={movePrevious}
            variant="light"
            className="ft-14 py-2 px-3 d-flex align-items-center justify-content-center font-weight-600 border-levenderblue previous-qs--btn"
          >
            {t(I18nKey.PREVIOUS_TEXT)}
          </Button>
          <Button
            disabled={activeQuestionIndex === section.questionList.length}
            onClick={moveNext}
            variant="success"
            className="ft-14 py-2 px-3 d-flex align-items-center justify-content-center font-weight-600 border-levenderblue next-qs--btn"
          >
            {t(I18nKey.NEXT_TEXT)}
          </Button>
        </div>
      )}
    </>
  );
};

export default memo(PreviousNextComponent);

export enum AppAction {
  INIT_TEST = 'init_test',
  UPDATE_LOADING = 'update_loading',
  ADD_TEST_DATA = 'add_test_data',
  UPDATE_ACTIVE_SECTION = 'update_active_section',
  UPDATE_ACTIVE_QUESTION = 'update_active_question',
  MARK_QUESTION_REVISIT = 'mark_question_revisit',
  SAVE_QUESTION_RES = 'save_question_res',
  CLEAR_QUESTION_RES = 'clear_question_res',
  UPDATE_LAST_SAVE_TIME = 'update_last_save_time',
}

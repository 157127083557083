import { createBrowserRouter } from 'react-router-dom';
import { SecureRoute } from '../security/secure.route';
import MainHolder from '../page/main.holder';
import TestPage from '../page/test/test.page';
import AuthPage from '../page/auth/auth.page';
import ResultPage from '../page/result/result.page';
import PreparePage from '../page/prepare/prepare.page';
import HomePage from '../page/home/home.page';
import NotFoundPage from '../page/not-found/not-found.page';
import { BaseApp } from '../page/base-app';

export const mainRoute = createBrowserRouter([
  {
    element: <BaseApp />,
    children: [
      {
        path: '/not-found',
        element: <NotFoundPage />,
        children: [],
      },
      {
        path: '/auth',
        element: <AuthPage />,
        children: [],
      },
      {
        path: '',
        element: <HomePage />,
        children: [],
      },
      {
        element: <SecureRoute element={<MainHolder />} />,
        children: [
          {
            path: '/prepare',
            element: <PreparePage />,
            children: [],
          },
          {
            path: '/test',
            element: <TestPage />,
            children: [],
          },
          {
            path: '/result',
            element: <ResultPage />,
            children: [],
          },
        ],
      },
      {
        path: '*',
        element: <h1>Page not found</h1>,
      },
    ],
  },
]);

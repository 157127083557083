import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import MultipleChoiceComponent from './question-response/multiple-choice.component';
import QuestionComponent from './question.component';
import { useWindow } from '../../../util/window-helper';
import { Question, Section } from '../../../response/test.response';
import { useApp } from '../../../hook/app.hook';
import { QuestionType } from '../../../enum/question-type.enum';
import SingleChoiceComponent from './question-response/sigle-choice.component';
import { memo } from 'react';
import IntigerAnsComponent from './question-response/intiger-ans.component';

const QuestionHolderComponent = () => {
  const { testRes, markQuestionForRevisit, clearQuestionResponse } = useApp();
  const { isMobile } = useWindow();
  const { t } = useTranslation();

  const question = testRes.sectionList
    .filter((section: Section) => section.active)[0]
    .questionList.filter((question: Question, index: number) => {
      return question.active;
    })[0];

  const getAnswerView = (question: Question): React.ReactNode => {
    const components: Record<QuestionType, React.ReactNode> = {
      [QuestionType.SINGLE_CHOICE]: <SingleChoiceComponent />,
      [QuestionType.MULTIPLE_CHOICE]: <MultipleChoiceComponent />,
      [QuestionType.INT_ANS]: <IntigerAnsComponent />,
    };

    return components[question.type] || <p>Question Impl not available</p>;
  };

  const answerView = getAnswerView(question);

  return (
    <>
      <Row
        className={`font-weight-500 ${
          isMobile ? '' : 'question-response--styles'
        } `}
      >
        <Col lg={6} xs={12} className="h-100">
          <div
            className={`w-100 ${
              isMobile ? 'pt-3' : ' me-4 pt-4 pe-3 h-100  '
            } `}
          >
            <div
              className={`${
                isMobile ? '' : 'border-end border-lightgray pe-4 h-100'
              }`}
            >
              <div className="d-flex align-items-center border-bottom border-lightgray">
                <h6 className="font-weight-600">
                  {t(I18nKey.TEST_QUESTION_TEXT)}
                  <span className="mx-1">{question.index}</span>
                </h6>
                <Button
                  variant="link"
                  className={`ms-auto ft-14 text-decoration-none d-flex align-items-center p-0 ${
                    question.markedForRevisit ? 'text-warning' : 'text-black'
                  }`}
                  onClick={() => markQuestionForRevisit(question.id)}
                >
                  <img
                    src={`/images/${
                      question.markedForRevisit
                        ? 'revisit-filled.svg'
                        : 'revisit-icon.svg'
                    }`}
                    className="me-2"
                    alt=""
                  />
                  {t(I18nKey.REVIST_LATTER_TEXT)}
                </Button>
              </div>

              <QuestionComponent question={question} />
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          xs={12}
          className={`h-100 ${
            isMobile ? 'pt-3 mt-2 response--wrapper ' : 'pt-4 px-3 ps-0'
          } `}
        >
          <div className="d-flex align-items-center border-bottom border-lightgray">
            <h6 className="font-weight-600">
              {t(I18nKey.ENTER_YOUR_RESPONSE_TEXT)}
            </h6>
            {question.attempted && (
              <Button
                variant="link"
                className="ms-auto ft-14 text-decoration-none d-flex align-items-center p-0"
                onClick={() => clearQuestionResponse(question.id)}
              >
                <img src="/images/clear-response.svg" className="me-2" alt="" />
                {t(I18nKey.CLEAR_RESPONSE_TEXT)}
              </Button>
            )}
          </div>
          <div
            className={`pt-4 ${
              isMobile ? 'response--mweb-h' : ' response--h'
            } response--area `}
          >
            {answerView}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default memo(QuestionHolderComponent);

import { Outlet } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { useInitTest } from './shared/hook/init.test';

const MainHolder = () => {
  const { loading } = useInitTest();

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default MainHolder;

import { useEffect, useState } from 'react';

export const useWindowChange = () => {
  const [windowMoveCount, setWindowMoveCount] = useState(0);
  const [fullScreenCloseCount, setFullScreenCloseCount] = useState(0);

  const handleResize = () => {
    const leftPosition = window.screenLeft || window.screenX;
    console.log('Window left position:', leftPosition);
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement === null) {
      console.log('Exited fullscreen');
      setFullScreenCloseCount((preState) => preState + 1);
    } else {
      console.log('Entered fullscreen');
      // Perform actions when the fullscreen mode is entered
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      console.log('Escape key pressed');
      // Perform actions when the Escape key is pressed
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Attach the fullscreenchange event listener when the component mounts
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Attach the keydown event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return { windowMoveCount, fullScreenCloseCount };
};

import { useContext } from 'react';
import { UserContext } from '../context/user.context';

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`Component is not inside UserContext scope`);
  }
  return context;
};

import { Outlet } from 'react-router';
import { useValidateTest } from './shared/hook/validate.test';
import { Spinner } from 'react-bootstrap';

export const BaseApp = () => {
  const { loading } = useValidateTest();

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <Outlet />
      )}
    </>
  );
};

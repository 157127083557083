import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { PreTestWindowEnum } from '../prepare.page';

const SystemCompatibilityComponent = (props: any) => {
  const { t } = useTranslation();
  const [isSystemComptibilityError, setIsSystemComptibilityError] =
    useState(false);

  return (
    <div className="d-flex justify-content-center flex-column">
      <h5 className="ft-16 font-weight-600">
        {t(I18nKey.ALL_SET_PROCEED_TEXT)}
      </h5>
      <div
        className={` ${
          isSystemComptibilityError ? 'bg-mistyrose' : 'bg-aliceblue'
        }  p-4 my-3 rounded-25 d-flex align-items-start`}
      >
        <img
          src={`/images/${
            isSystemComptibilityError
              ? 'error-exclaimation.svg'
              : 'check-blue.svg'
          }`}
          alt=""
        />
        <div className="ps-3">
          <h5 className="ft-16 font-weight-600">
            {t(I18nKey.SYSTEM_COMPATIBILITY_TEXT)}
          </h5>
          <div className="ft-14 font-weight-400 mt-3">
            {t(I18nKey.GRAMMER_SPELL_PLUGIN_CONTENT)}
          </div>
        </div>
      </div>
      <div className="d-flex">
        <Button
          variant="primary"
          type="submit"
          className="rounded-25 px-lg-4 mt-lg-3 mt-2 font-weight-600 mx-lg-0 mx-1"
          onClick={() =>
            props.handleProceed(PreTestWindowEnum.SECTIONQUESTIONLIST)
          }
        >
          {t(I18nKey.PROCEED_TEXT)}
          <img src="/images/right-arrow-white.svg" alt="" className="ms-3" />
        </Button>
      </div>
    </div>
  );
};

export default SystemCompatibilityComponent;

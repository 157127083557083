import {
  Option,
  Question,
  Section,
  TestRes,
} from '../../../response/test.response';
import { CandidateResponseService } from '../../../service/candidate-response.service';
import {
  CandidateResReq,
  OptionCandidateRes,
  QuestionCandidateRes,
  SectionCandidateRes,
} from '../../../request/candidate-response.request';

export const useSaveResponse = () => {
  const saveTest = async (
    testRes: TestRes,
    closed: boolean,
  ): Promise<any[]> => {
    const candidateResReq = {
      testId: testRes.id,
      timeTaken: Number(testRes.timeTaken.toFixed(0)),
      sectionList: testRes.sectionList.map((section: Section) => {
        return {
          id: section.id,
          timeTaken: Number(section.timeTaken.toFixed(0)),
          fullMark: section.fullMark,
          attempted: section.attempted,
          questionList: section.questionList.map((question: Question) => {
            return {
              id: question.id,
              candidateAnswer: question.candidateAnswer,
              timeTaken: Number(question.timeTaken.toFixed(0)),
              markedForRevisit: question.markedForRevisit,
              attempted: question.attempted,
              optionList: question.optionList.map((option: Option) => {
                return {
                  id: String(option.id),
                  selected: option.selected ? option.selected : false,
                } as OptionCandidateRes;
              }),
            } as QuestionCandidateRes;
          }),
        } as SectionCandidateRes;
      }),
      attempted: true,
      closed: closed,
      startTime: testRes.startTime,
      endTime: new Date().getTime(),
    } as CandidateResReq;

    return await CandidateResponseService.save(candidateResReq);
  };

  return {
    saveTest,
  };
};

import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { useTheme } from '../../themes/theme.provider';
import QuestionPaginationComponent from './component/question-pagination.component';
import FilterByComponent from './component/filter-by.component';
import FullScreenComponent from './component/full-screen.component';
import InstructionComponent from './component/instruction.component';
import OverviewComponent from './component/overview.component';
import QuestionHolderComponent from './component/question-holder.component';
import SectionPickerComponent from './component/section-picker.component';
import SideMenuComponent from './component/side-menu.component';
import TimerComponent from './component/timer.component';
import PreviousNextComponent from './component/previous-next.component';
import QuestionInfoComponent from './component/question-info.component';

const TestMPage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [isOpenBottomWarpper, setIsOpenBottomWarpper] = useState(false);

  const handleBottomWrapper = () => {
    setIsOpenBottomWarpper(!isOpenBottomWarpper);
  };

  return (
    <>
      <section className="pt-3 d-flex flex-column mweb-section">
        <Row className="m-0 mb-1">
          <Col
            lg={12}
            className="px-3 border-bottom border-gainsboro pb-2 text-white d-flex align-items-center"
          >
            <SideMenuComponent />

            <div className="border-start border-gainsboro">
              <img src="/images/sassy-logo.svg" width="80" alt="" />
            </div>

            <div className="ms-auto d-flex align-items-center">
              <FullScreenComponent />

              <OverviewComponent />
            </div>
          </Col>
        </Row>
        <div className="py-2 px-3 d-flex align-items-center justify-content-between">
          <InstructionComponent />
          <TimerComponent />
        </div>

        <div className="bg-secondary rounded-25 px-3 m-3">
          <QuestionHolderComponent />
        </div>

        <div
          className={`bottom--wrapper bg-secondary d-flex align-items-center text-center flex-column py-2 ${
            isOpenBottomWarpper ? 'opend' : ''
          } `}
        >
          <Button
            variant="link"
            className="text-white p-0 border-0"
            onClick={handleBottomWrapper}
          >
            <img
              src={`/images/${
                isOpenBottomWarpper ? 'down-arrow.svg' : 'up-arrow.svg'
              }`}
              alt=""
            />
          </Button>

          <div className="w-100">
            <PreviousNextComponent />
            {isOpenBottomWarpper && (
              <>
                <div className="border-top border-gray mt-2 py-2">
                  <QuestionInfoComponent />

                  <div className="d-flex align-items-center justify-content-between px-3">
                    <SectionPickerComponent />
                    <FilterByComponent />
                  </div>

                  <div
                    style={{ background: theme.primary }}
                    className=" w-100 mweb--pagination-wrapper mt-3 px-3 py-4 d-flex justify-content-center align-items-center flex-column"
                  >
                    <QuestionPaginationComponent />

                    <div
                      style={{ color: theme.themewhite }}
                      className="mx-2 mt-auto"
                    >
                      <img src="/images/cloud.svg" alt="" />
                      <span className="mx-2">
                        {t(I18nKey.SAVED_TEXT)}:{' '}
                        <b style={{ color: theme.themewhite }} className="me-1">
                          0:02 secs
                        </b>{' '}
                        {t(I18nKey.AGO_TEXT)}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TestMPage;

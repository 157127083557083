import { AppAction } from '../action/app.action';
import { Action } from '../model/action.model';
import {
  OptionCandidateRes,
  QuestionCandidateRes,
  SectionCandidateRes,
} from '../request/candidate-response.request';
import { Option, Question, Section } from '../response/test.response';
import { AppState } from '../state/app.state';

export const AppReducer = (state: AppState, action: Action): AppState => {
  const { type, payload } = action;

  switch (type) {
    case AppAction.INIT_TEST:
      return {
        ...state,
        testInitData: { ...payload.testInitData },
      };
    case AppAction.ADD_TEST_DATA:
      return {
        ...state,
        ...(!payload.inProgress && {
          testRes: {
            ...payload.testRes,
            timeTaken: payload.testRes.timeTaken
              ? payload.testRes.timeTaken
              : 0,
            sectionList: payload.testRes.sectionList.map(
              (section: Section, sectionIndex: number) => {
                return {
                  ...section,
                  timeTaken: section.timeTaken ? section.timeTaken : 0,
                  attempted: section.attempted ? section.attempted : false,
                  questionList: section.questionList.map(
                    (question: Question, index: number) => {
                      return {
                        ...question,
                        timeTaken: question.timeTaken ? question.timeTaken : 0,
                        attempted: question.attempted
                          ? question.attempted
                          : false,
                        markedForRevisit: question.markedForRevisit
                          ? question.markedForRevisit
                          : false,
                        active: index === 0,
                        index: index + 1,
                      };
                    },
                  ),
                  active: sectionIndex === 0,
                };
              },
            ),
          },
        }),
        ...(payload.inProgress && {
          testRes: {
            ...payload.testRes,
            timeTaken: payload.candidateResponse.timeTaken
              ? payload.candidateResponse.timeTaken
              : 0,
            sectionList: payload.testRes.sectionList.map(
              (section: Section, sectionIndex: number) => {
                const sectionCandidateRes =
                  payload.candidateResponse.sectionList.filter(
                    (sectionCandidateRes: SectionCandidateRes) => {
                      return sectionCandidateRes.id === section.id;
                    },
                  )[0];
                return {
                  ...section,
                  timeTaken: sectionCandidateRes.timeTaken
                    ? sectionCandidateRes.timeTaken
                    : 0,
                  attempted: sectionCandidateRes.attempted
                    ? sectionCandidateRes.attempted
                    : false,
                  active: sectionIndex === 0,
                  questionList: section.questionList.map(
                    (question: Question, index: number) => {
                      const questionCandidateRes =
                        sectionCandidateRes.questionList.filter(
                          (questionCandidateRes: QuestionCandidateRes) => {
                            return questionCandidateRes.id === question.id;
                          },
                        )[0];
                      return {
                        ...question,
                        timeTaken: questionCandidateRes.timeTaken
                          ? questionCandidateRes.timeTaken
                          : 0,
                        attempted: questionCandidateRes.attempted
                          ? questionCandidateRes.attempted
                          : false,
                        markedForRevisit: questionCandidateRes.markedForRevisit
                          ? questionCandidateRes.markedForRevisit
                          : false,
                        candidateAnswer: questionCandidateRes.candidateAnswer,
                        active: index === 0,
                        index: index + 1,
                        optionList: question.optionList.map(
                          (option: Option) => {
                            const optionCandidateRes =
                              questionCandidateRes.optionList.filter(
                                (optionCandidateRes: OptionCandidateRes) => {
                                  return optionCandidateRes.id === option.id;
                                },
                              )[0];
                            return {
                              ...option,
                              selected: optionCandidateRes.selected,
                            };
                          },
                        ),
                      };
                    },
                  ),
                };
              },
            ),
          },
        }),
        candidateResponse: payload.candidateResponse,
        inProgress: payload.inProgress,
      };
    case AppAction.UPDATE_ACTIVE_SECTION:
      return {
        ...state,
        testRes: {
          ...state.testRes,
          timeTaken: state.testRes.timeTaken + payload.timeTaken,
          sectionList: state.testRes.sectionList.map((section: Section) => {
            const secActive = section.id === payload.sectionId;
            return {
              ...section,
              ...(section.active && {
                timeTaken: section.timeTaken + payload.timeTaken,
              }),
              ...(secActive && {
                questionList: section.questionList.map(
                  (question: Question, index: Number) => {
                    return {
                      ...question,
                      ...(question.active && {
                        timeTaken: question.timeTaken + payload.timeTaken,
                      }),
                      active: secActive && index === 0,
                    };
                  },
                ),
              }),
              active: secActive,
            };
          }),
        },
      };
    case AppAction.UPDATE_ACTIVE_QUESTION:
      return {
        ...state,
        testRes: {
          ...state.testRes,
          timeTaken: state.testRes.timeTaken + payload.timeTaken,
          sectionList: state.testRes.sectionList.map((section: Section) => {
            return {
              ...section,
              ...(section.active && {
                timeTaken: section.timeTaken + payload.timeTaken,
              }),
              questionList: section.questionList.map((question: Question) => {
                return {
                  ...question,
                  ...(question.active && {
                    timeTaken: question.timeTaken + payload.timeTaken,
                  }),
                  active: question.id === payload.questionId,
                };
              }),
            };
          }),
        },
      };

    case AppAction.MARK_QUESTION_REVISIT:
      return {
        ...state,
        testRes: {
          ...state.testRes,
          sectionList: state.testRes.sectionList.map((section: Section) => {
            return {
              ...section,
              questionList: section.questionList.map((question: Question) => {
                return {
                  ...question,
                  ...(question.id === payload.questionId && {
                    markedForRevisit: !question.markedForRevisit,
                  }),
                };
              }),
            };
          }),
        },
      };

    case AppAction.SAVE_QUESTION_RES:
      return {
        ...state,
        testRes: {
          ...state.testRes,
          sectionList: state.testRes.sectionList.map((section: Section) => {
            return {
              ...section,
              ...(section.active && {
                questionList: section.questionList.map((question: Question) => {
                  return {
                    ...question,
                    ...(question.id === payload.questionId &&
                      payload.candidateAnswer && {
                        candidateAnswer: payload.candidateAnswer,
                        attempted: true,
                      }),
                    ...(question.id === payload.questionId &&
                      payload.optionIdList && {
                        optionList: question.optionList.map(
                          (option: Option) => {
                            return {
                              ...option,
                              selected: payload.optionIdList.includes(
                                option.id,
                              ),
                            };
                          },
                        ),
                        attempted:
                          payload.candidateAnswer ||
                          (payload.optionIdList &&
                            payload.optionIdList.length > 0),
                      }),
                  };
                }),
              }),
              ...(section.active && {
                attempted: true,
              }),
            };
          }),
        },
      };

    case AppAction.CLEAR_QUESTION_RES:
      return {
        ...state,
        testRes: {
          ...state.testRes,
          sectionList: state.testRes.sectionList.map((section: Section) => {
            return {
              ...section,
              ...(section.active && {
                questionList: section.questionList.map((question: Question) => {
                  return {
                    ...question,
                    ...(question.id === payload.questionId && {
                      candidateAnswer: null,
                      optionList: question.optionList.map((option: Option) => {
                        return {
                          ...option,
                          selected: false,
                        };
                      }),
                      attempted: false,
                    }),
                  };
                }),
              }),
            };
          }),
        },
      };
    case AppAction.UPDATE_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case AppAction.UPDATE_LAST_SAVE_TIME:
      return {
        ...state,
        lastSaveTime: payload.lastSaveTime,
      };
    default:
      throw new Error(`No case for type ${type} found in AppReducer.`);
  }
};

import { memo, useEffect, useState } from 'react';
import { Button, Col, Offcanvas, ProgressBar, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { useWindow } from '../../../util/window-helper';
import { useApp } from '../../../hook/app.hook';
import { Question, Section } from '../../../response/test.response';
import RemainingTimeCompponent from './remaining-time.component';
import { useTimer } from '../../../hook/timer.hook';

const graphLegends = ['Attempted', 'Marked for Revisit', 'Unattempted'];

const OverviewComponent = () => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();
  const [showOverviewCanvas, setShowOverviewCanvas] = useState(false);

  const { testRes, saveResponse } = useApp();
  const { startTime } = useTimer();
  const { openResult } = useSSNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [attemptedCount, setAttemptedCount] = useState(0);
  const [markedForRevisitCount, setMarkedForRevisitCount] = useState(0);
  const [unattemptedCount, setUnattemptedCount] = useState(0);

  useEffect(() => {
    let _totalCount = 0;
    let _attemptedCount = 0;
    let _markedForRevisitCount = 0;
    let _unattemptedCount = 0;
    testRes.sectionList.map((section: Section) => {
      section.questionList.map((question: Question) => {
        _totalCount++;
        if (question.attempted) {
          _attemptedCount++;
        } else {
          _unattemptedCount++;
        }
        if (question.markedForRevisit) {
          _markedForRevisitCount++;
        }
      });
    });

    setTotalCount(_totalCount);
    setAttemptedCount(_attemptedCount);
    setMarkedForRevisitCount(_markedForRevisitCount);
    setUnattemptedCount(_unattemptedCount);
  }, [testRes]);

  const options = {
    chart: {
      type: 'pie',
      height: '260px',
      width: 320,
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: false, // Set this to false to hide the tooltip
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        center: ['50%', '60%'],
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        innerSize: '40%',
        data: [
          {
            name: 'Attempted:',
            y: attemptedCount,
            color: '#002A52',
          },
          {
            name: 'Marked for Revisit:',
            y: markedForRevisitCount,
            color: '#FEB040',
          },
          {
            name: 'Unattempted:',
            y: unattemptedCount,
            color: '#BACAFF',
          },
        ],
      },
    ],
  };

  const finishTest = () => {
    const currentTime = new Date().getTime();
    const _timeTaken = (currentTime - startTime) / 1000;

    const _testRes = {
      ...testRes,
      timeTaken: testRes.timeTaken + _timeTaken,
      sectionList: testRes.sectionList.map((section: Section) => {
        return {
          ...section,
          ...(section.active && {
            timeTaken: section.timeTaken + _timeTaken,
            questionList: section.questionList.map((question: Question) => {
              return {
                ...question,
                ...(question.active && {
                  timeTaken: question.timeTaken + _timeTaken,
                }),
              };
            }),
          }),
        };
      }),
    };

    saveResponse(_testRes, true, openResult);
  };

  return (
    <>
      <Button
        variant="danger"
        className="text-white ms-2 ft-14 rounded-25 font-weight-600"
        onClick={() => setShowOverviewCanvas(true)}
      >
        {t(I18nKey.END_TEXT)}
      </Button>

      <Offcanvas
        show={showOverviewCanvas}
        onHide={() => setShowOverviewCanvas(false)}
        placement="end"
        className="test-w-canvas over-flow-auto h-100"
      >
        <Offcanvas.Header className="pt-4 pb-2 px-4 border-bottom border-whisper">
          <div className="d-flex align-items-lg-center flex-lg-row flex-column w-100">
            <Offcanvas.Title className="ft-14 font-weight-600 d-flex align-items-center">
              <img src="/images/exclaimation-red.svg" className="me-2" alt="" />
              {t(I18nKey.FINISH_TEXT)}
            </Offcanvas.Title>
            <div className="ms-lg-auto d-flex align-items-center small">
              <span className={`d-flex ${isMobile ? 'mt-2' : ''} `}>
                <img src="/images/timer-black.svg" alt="" />
                <span className="ms-1">
                  {t(I18nKey.REMAINING_TIME_TEXT)}:
                  <RemainingTimeCompponent />
                </span>
              </span>
              <Button
                variant="link"
                className={`text-white p-0 ${
                  isMobile ? 'ms-auto mtm-50' : 'ms-4'
                } `}
                onClick={() => setShowOverviewCanvas(false)}
              >
                <img src="/images/close-icon.svg" height="18" alt="" />
              </Button>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="canvas-fixed-ele ps-2 pe-4">
            <div className="d-flex align-items-center flex-lg-row flex-column">
              <HighchartsReact highcharts={Highcharts} options={options} />
              <div
                className={`d-flex flex-column align-items-center ${
                  isMobile ? 'w-100 mt-4' : 'mt-5'
                } `}
              >
                <h6 className="font-weight-600">
                  {t(I18nKey.YOUR_TEST_SUMMARY_TEXT)}
                </h6>
                <div className="d-flex align-items-center">
                  <h3 className="font-weight-600 m-0">{totalCount}</h3>
                  <span className="text-dimgray ms-2 font-weight-500">
                    {t(I18nKey.TOTAL_QUESTION_KEY)}
                  </span>
                </div>
                <div className="pt-4 pb-2">
                  {graphLegends.map((items) => {
                    return (
                      <div className="font-weight-600 mb-2 d-flex align-items-center">
                        {items === t(I18nKey.ATTEMPTED) && (
                          <>
                            <span className="circle-dot rounded-circle bg-primary"></span>
                            <span className="mx-2 text-dimgray small">
                              {t(I18nKey.ATTEMPTED)}:
                            </span>
                            <span>
                              {attemptedCount}/{totalCount}
                            </span>
                          </>
                        )}
                        {items === t(I18nKey.MARKED_FOR_VISITED) && (
                          <>
                            <span className="circle-dot rounded-circle bg-warning"></span>
                            <span className="mx-2 text-dimgray small">
                              {t(I18nKey.MARKED_FOR_VISITED)}:
                            </span>
                            <span>
                              {markedForRevisitCount}/{totalCount}
                            </span>
                          </>
                        )}
                        {items === t(I18nKey.UNATTEMPTED) && (
                          <>
                            <span className="circle-dot rounded-circle bg-levenderblue"></span>
                            <span className="mx-2 text-dimgray small">
                              {t(I18nKey.UNATTEMPTED)}:
                            </span>
                            <span>
                              {unattemptedCount}/{totalCount}
                            </span>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <section className="mt-lg-3 py-4 px-3 border-top border-whisper">
              <h6 className="font-weight-600 ft-14">
                {t(I18nKey.SECTION_SUMMARY_TEXT)}
              </h6>
              <div className="border border-success rounded-25 mt-2 ft-14">
                <Row className="m-0 py-2 px-2 top-l-r-rounded bg-light">
                  <Col xs={2}>#</Col>
                  <Col xs={4}>{t(I18nKey.SECTION_NAME_TEXT)}</Col>
                  <Col xs={6} className="d-flex text-end flex-column">
                    {t(I18nKey.STATUS_TEXT)}
                  </Col>
                </Row>

                {testRes.sectionList.map((section: Section, index: number) => {
                  let _totalCount = 0;
                  let _attemptedCount = 0;
                  let _markedForRevisitCount = 0;
                  let _unattemptedCount = 0;
                  section.questionList.map((question: Question) => {
                    _totalCount++;
                    if (question.attempted) {
                      _attemptedCount++;
                    } else {
                      _unattemptedCount++;
                    }
                    if (question.markedForRevisit) {
                      _markedForRevisitCount++;
                    }
                  });
                  return (
                    <Row className="m-0 py-2 px-2">
                      <Col xs={2}>{index + 1}.</Col>
                      <Col xs={4} className="d-flex flex-column">
                        <span className="ft-14 font-weight-600">
                          {section.name.en}
                        </span>
                        {section.timed ? (
                          <span className="ft-12 text-dimgray">
                            {section.maxTime / 60} minutes
                          </span>
                        ) : (
                          <span className="ft-12 text-dimgray">untimed</span>
                        )}
                      </Col>
                      <Col xs={6} className="d-flex text-end flex-column">
                        <ProgressBar className="rounded-0">
                          <ProgressBar
                            variant="primary"
                            label={_attemptedCount}
                            now={_attemptedCount * 100}
                            key={1}
                          />
                          <ProgressBar
                            variant="warning"
                            label={_markedForRevisitCount}
                            now={_markedForRevisitCount * 100}
                            key={2}
                          />
                          <ProgressBar
                            variant="levenderblue"
                            label={_unattemptedCount}
                            now={_unattemptedCount * 100}
                            key={3}
                          />
                        </ProgressBar>
                        <span className="ft-12 text-dimgray">
                          {t(I18nKey.TOTAL_TEXT)}: {_totalCount} Questions
                        </span>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </section>
          </div>
          <div className="mt-auto bg-light py-3 px-4 d-flex align-items-center position-fixed overview--btns">
            <Button
              variant="danger"
              className="rounded-25 text-white"
              onClick={() => finishTest()}
            >
              {t(I18nKey.YES_END_TEXT)}
            </Button>
            <Button
              variant="secondary"
              className="rounded-25 d-flex mx-3 border-portage"
              onClick={() => setShowOverviewCanvas(false)}
            >
              {t(I18nKey.NP_BACK_TO_TEST_TEXT)}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(OverviewComponent);

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const useSSNavigate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const openAuth = () => {
    navigate('/auth', { replace: true });
  };

  const openNotFound = () => {
    navigate('/not-found', { replace: true });
  };

  const openPrepare = () => {
    navigate(`/prepare?${searchParams}`, { replace: true });
  };

  const openTest = () => {
    navigate(`/test?${searchParams}`, { replace: true });
  };

  const openResult = () => {
    navigate(`/result?${searchParams}`, { replace: true });
  };

  const closeWindow = () => {
    window.location.replace(`https://saissy.ai/`);
  };

  return {
    openAuth,
    openNotFound,
    openPrepare,
    openTest,
    openResult,
    closeWindow,
  };
};

import { CandidateResReq } from '../request/candidate-response.request';
import httpClient from '../util/http-client';

export const CandidateResponseService = {
  save: async (
    candidateResReq: CandidateResReq,
  ): Promise<(CandidateResReq | never)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post(`/candidate-response`, candidateResReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};

import './App.css';
import { RouterProvider } from 'react-router';
import { mainRoute } from './util/main.route';
import { useI18n } from './i18n/i18n.hook';
import { useFont } from './font/font.hook';
import { AppStateProvider } from './context/app.context';
import { TimerStateProvider } from './context/timer.context';
import { UserStateProvider } from './context/user.context';

function App() {
  useI18n();
  useFont();

  return (
    <TimerStateProvider>
      <AppStateProvider>
        <UserStateProvider>
          <RouterProvider router={mainRoute} />
        </UserStateProvider>
      </AppStateProvider>
    </TimerStateProvider>
  );
}

export default App;

import { memo, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { ThemeFontSizeSettings } from './setting.component';

const SideMenuComponent = () => {
  const { t } = useTranslation();
  const { theme, themeType } = useTheme();
  const [showSideMenu, setShowSideMenu] = useState(false);

  return (
    <>
      <Button
        variant="link"
        className="text-white me-3 p-0 border-0"
        onClick={() => setShowSideMenu(true)}
      >
        <img
          src={`/images/${
            themeType === 'light' ? 'burger-dark.svg' : 'burger.svg'
          }`}
          alt=""
        />
      </Button>

      <Offcanvas
        show={showSideMenu}
        onHide={() => setShowSideMenu(false)}
        placement="start"
        className="side-menu--canvas p-0"
      >
        <Offcanvas.Header
          style={{ background: theme.primary }}
          className="d-flex flex-column align-items-center"
        >
          <div className="d-flex w-100">
            <img src="/images/sassy-logo.svg" width="80" alt="" />

            <Button
              variant="link"
              className="text-white ms-auto p-0"
              onClick={() => setShowSideMenu(false)}
            >
              <img src="/images/circle-right-arrow.svg" height="25" alt="" />
            </Button>
          </div>
          <Offcanvas.Title
            style={{ color: theme.themewhite }}
            className="ft-14 font-weight-600 text-start w-100 mt-3 ms-4"
          >
            Test
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-2 d-flex flex-column">
          <div className="text-black mx-2 ft-16 font-weight-600">
            Pankaj Mishra
          </div>
          <div className="mt-4 py-4 mx-2 border-top border-bottom border-gray">
            <ThemeFontSizeSettings />
          </div>
          <div className="py-4 mx-2 border-bottom border-gray">
            <img src="/images/wi-fi-icon.svg" alt="" />
            <span className="ms-3">{t(I18nKey.GOOD_CONNECTION_TEXT)}</span>
          </div>
          <div className="py-4 mx-2 border-bottom border-gray">
            <img src="/images/cloud-icon.svg" alt="" />
            <span className="ms-3">{t(I18nKey.RESPONSE_SAVED_TEXT)}</span>
          </div>
          <div className="py-4 mx-2">
            <div className="mb-2">{t(I18nKey.NEED_HELP_ONLY_TEXT)}</div>
            <div className="mb-2">
              {t(I18nKey.CONTACT_US_TEXT)} <b>+91 99876 54321</b>
            </div>
          </div>
          <div className="py-4 mx-2 mt-auto">
            <div className="d-flex align-items-center mb-3">
              <span className="me-1">{t(I18nKey.POWERED_BY_TEXT)}</span>
              <img src="/images/sassy-logo-light.svg" height="20" alt="" />
            </div>
            <div>{t(I18nKey.COPYRIGHT_ASSESSMENT_DATA)}</div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(SideMenuComponent);

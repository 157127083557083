import { memo, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import { CKTextView } from '../../../view/ck-text.view';
import { useApp } from '../../../hook/app.hook';
import RemainingTimeCompponent from './remaining-time.component';

const InstructionComponent = () => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [showInstructionCanvas, setShowInstructionCanvas] = useState(false);

  const { testRes } = useApp();

  return (
    <>
      {isMobile && (
        <Button
          variant="light"
          style={{ color: theme.themewhite }}
          className="bg-transparent p-0 border-0 ft-14"
          onClick={() => setShowInstructionCanvas(true)}
        >
          <img src="/images/instructions.svg" className="me-2" alt="" />
          {t(I18nKey.INSTRUCTIONS_TEXT)}
        </Button>
      )}
      {!isMobile && (
        <Button
          variant="light"
          className={`px-2 py-1 mx-2 ${
            showInstructionCanvas ? 'border-primary' : 'border-levenderblue'
          } `}
          onClick={() => setShowInstructionCanvas(true)}
        >
          <img src="/images/instruction.svg" alt="" />
        </Button>
      )}

      <Offcanvas
        show={showInstructionCanvas}
        onHide={() => setShowInstructionCanvas(false)}
        placement="end"
        className="instructions--canvas p-3"
      >
        <Offcanvas.Header
          className={isMobile ? 'pt-0 border-bottom border-gray me-2 pe-0' : ''}
        >
          <div className="d-flex align-items-lg-center flex-lg-row flex-column w-100">
            <Offcanvas.Title className="ft-14 font-weight-600 text-decoration-underline d-flex">
              <span>{t(I18nKey.GENERAL_INSTRUCTIONS_TEXT)}</span>
              {isMobile && (
                <Button
                  variant="link"
                  className="text-white ms-4 p-0 ms-auto"
                  onClick={() => setShowInstructionCanvas(false)}
                >
                  <img src="/images/close-icon.svg" height="18" alt="" />
                </Button>
              )}
            </Offcanvas.Title>
            <div className="ms-lg-auto d-flex align-items-center small">
              <span className="d-flex">
                <img src="/images/timer-black.svg" alt="" />
                <span className="ms-1">
                  {t(I18nKey.REMAINING_TIME_TEXT)}:
                  <RemainingTimeCompponent />
                </span>
              </span>
              {!isMobile && (
                <Button
                  variant="link"
                  className="text-white ms-4 p-0"
                  onClick={() => setShowInstructionCanvas(false)}
                >
                  <img src="/images/close-icon.svg" height="18" alt="" />
                </Button>
              )}
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className={isMobile ? 'ps-2 pe-3' : ''}>
          <ul
            className={`list-style-none border border-success rounded-25 mt-2 ${
              isMobile ? 'p-2' : 'px-4 py-3'
            } `}
          >
            <p className="mb-1">
              The Questions Palette displayed on the right side of screen will
              show the status of each question using one of the following
              symbols:
            </p>

            <li className="py-2 d-flex align-items-center">
              <span className="circle-badge d-flex align-items-center justify-content-center rounded-circle me-3 border border-levenderblue">
                3
              </span>
              <span>Unattempted or unmarked questions</span>
            </li>
            <li className="py-2 d-flex align-items-center">
              <span className="circle-badge d-flex align-items-center justify-content-center rounded-circle me-3 border border-warning">
                4
              </span>
              <span>Questions marked for revisits</span>
            </li>
            <li className="py-2 d-flex align-items-center">
              <span className="circle-badge d-flex align-items-center justify-content-center rounded-circle me-3 border border-primary">
                5
              </span>
              <span>Attempted or marked questions</span>
            </li>
          </ul>

          <p className="ft-14 font-weight-600 text-decoration-underline d-flex">
            {t(I18nKey.GENERAL_INSTRUCTIONS_TEXT)}
          </p>
          <div
            className={`question--area list-style-none border border-success rounded-25 mt-2 ${
              isMobile ? 'p-2' : 'px-3 py-2'
            } `}
          >
            <CKTextView initData={testRes.instruction.en} />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(InstructionComponent);

import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import FooterComponent from '../shared/footer.component';
import QuestionPaginationComponent from './component/question-pagination.component';
import ForwardBackwardComponent from './component/forward-backward.component';
import HeaderComponent from './component/header.component';
import InstructionComponent from './component/instruction.component';
import OverviewComponent from './component/overview.component';
import SectionPickerComponent from './component/section-picker.component';
import SettingComponent from './component/setting.component';
import TimerComponent from './component/timer.component';
import QuestionHolderComponent from './component/question-holder.component';
import FilterByComponent from './component/filter-by.component';
import FullScreenComponent from './component/full-screen.component';
import PreviousNextComponent from './component/previous-next.component';
import QuestionInfoComponent from './component/question-info.component';

const TestDPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="mx-xl-5 mx-lg-4 mx-3 px-1 pt-4 d-flex flex-column q--section--wrapper">
        <Row className="m-0 mb-1">
          <Col lg={12} className="px-0 text-white d-flex align-items-center">
            <HeaderComponent />
            <div className="ms-auto d-flex align-items-center">
              <TimerComponent />

              <FullScreenComponent />

              <SettingComponent />

              <OverviewComponent />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className="text-white d-flex align-items-center justify-content-center">
              <QuestionPaginationComponent />

              <FilterByComponent />
            </div>
          </Col>
        </Row>

        <ForwardBackwardComponent />

        <div className="bg-secondary rounded-25 flex-grow-1 py-3 px-4 mb-3">
          <Row>
            <Col lg={4}>
              <div className="d-flex align-items-center">
                <SectionPickerComponent />
                <InstructionComponent />
              </div>
            </Col>

            <Col lg={3} className="ps-5">
              <QuestionInfoComponent />
            </Col>

            <Col lg={5}>
              <PreviousNextComponent />
            </Col>
          </Row>

          <QuestionHolderComponent />
        </div>

        <FooterComponent />
      </section>
    </>
  );
};

export default TestDPage;

import { createContext, useReducer } from 'react';
import { UserState } from '../state/user.state';
import { UserRes } from '../response/user.response';
import { UserReducer } from '../reducer/user.reducer';
import { UserService } from '../service/user.service';
import { SecurityManager } from '../security/security-manager';
import { UserAction } from '../action/user.action';

const initialState = {
  userRes: {} as UserRes,
} as UserState;

export const UserContext = createContext<any>(initialState);

export const UserStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const fetchUser = async () => {
    const [userRes, err] = await UserService.getUserById(
      SecurityManager.getUid(),
    );

    if (err) {
    } else {
      dispatch({
        type: UserAction.SET_USER,
        payload: {
          userRes: userRes,
        },
      });
    }
  };

  const value = {
    userRes: state.userRes,
    fetchUser: fetchUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

import { useEffect, useState } from 'react';

export const useTabChange = () => {
  const [tabMoveCount, setTabMoveCount] = useState(0);
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      setTabMoveCount((preState) => preState + 1);
    } else {
      console.log('Tab is visible');
    }
  };
  useEffect(() => {
    // Attach the visibilitychange event listener when the component mounts
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { tabMoveCount };
};

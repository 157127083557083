import { TimerAction } from '../action/timer.action';
import { Action } from '../model/action.model';
import { TimerState } from '../state/timer.state';

export const TimerReducer = (state: TimerState, action: Action): TimerState => {
  const { type, payload } = action;

  switch (type) {
    case TimerAction.INIT_TIMER:
      return {
        ...state,
        stage: payload.stage,
        startTime: payload.startTime,
        endTime: payload.endTime,
      };
    case TimerAction.START_TIMER:
      return {
        ...state,
        stage: payload.stage,
        startTime: payload.startTime,
        endTime: payload.endTime,
      };
    case TimerAction.STOP_TIMER:
      return {
        ...state,
        stage: payload.stage,
        startTime: payload.startTime,
        endTime: payload.endTime,
      };
    case TimerAction.RESET_TIMER:
      return {
        ...state,
        startTime: payload.startTime,
        endTime: payload.endTime,
      };
    default:
      throw new Error(`No case for type ${type} found in TimerReducer.`);
  }
};

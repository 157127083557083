import { createContext, useReducer } from 'react';
import { TimerAction } from '../action/timer.action';
import { TimerStage } from '../enum/timer-stage.enum';
import { TimerReducer } from '../reducer/timer.reducer';
import { TimerState } from '../state/timer.state';

const initialState = {
  startTime: 0,
  endTime: 0,
  stage: TimerStage.NotInit,
} as TimerState;

export const TimerContext = createContext<any>(initialState);

export const TimerStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(TimerReducer, initialState);

  const initTimer = (endTime?: number) => {
    dispatch({
      type: TimerAction.INIT_TIMER,
      payload: {
        stage: TimerStage.NotInit,
        startTime: 0,
        endTime: endTime,
      },
    });
  };

  const startTimer = () => {
    let _endTime = new Date().getTime();
    if (state.endTime && state.endTime > 0) {
      _endTime = state.endTime;
    }
    dispatch({
      type: TimerAction.START_TIMER,
      payload: {
        stage: TimerStage.Started,
        startTime: _endTime,
        endTime: 0,
      },
    });
  };

  const stopTimer = () => {
    dispatch({
      type: TimerAction.STOP_TIMER,
      payload: {
        stage: TimerStage.Stopped,
        startTime: state.startTime,
        endTime: new Date().getTime(),
      },
    });
  };

  const reSetTimer = (): number => {
    const currentTime = new Date().getTime();
    const timeTaken = (currentTime - state.startTime) / 1000;

    dispatch({
      type: TimerAction.RESET_TIMER,
      payload: {
        startTime: currentTime,
        endTime: 0,
      },
    });

    return timeTaken;
  };

  const value = {
    stage: state.stage,
    startTime: state.startTime,
    initTimer,
    startTimer,
    stopTimer,
    reSetTimer,
  };

  return (
    <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
  );
};

import { useState } from 'react';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { PreTestWindowEnum } from '../prepare.page';

export enum GenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  TRANSGENDER = 'transgender',
}

const RegisterComponent = (props: any) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedGender, setSelectedGender] = useState(GenderEnum.FEMALE);

  const selectGender = (e: any) => {
    setSelectedGender(e.target.value);
  };

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity()) {
      setIsError(false);
      props.handleProceed(PreTestWindowEnum.SECTIONQUESTIONLIST);
    } else {
      setIsError(true);
    }
  };

  return (
    <div className="d-flex justify-content-center flex-column text-black ft-14">
      <h5 className="ft-16 font-weight-600 mb-4">
        {t(I18nKey.REGISTRATION_HEADING)}
      </h5>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            lg={4}
            md={6}
            xs={12}
            className="light-input-wrapper mb-lg-0 mb-4"
          >
            <Form.Label className="text-black font-weight-600 text-primary">
              {t(I18nKey.EMAIL_ADDRESS_TEXT)}
            </Form.Label>

            <Form.Control
              required
              type="text"
              placeholder={t(I18nKey.ENTER_EMAIL_ID_TEXT)}
              aria-describedby={t(I18nKey.ENTER_EMAIL_ID_TEXT)}
              className={`rounded-25 ${
                isError ? 'border-danger' : 'border-portage'
              } `}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid email ID
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            lg={4}
            md={6}
            xs={12}
            className="light-input-wrapper mb-lg-0 mb-4"
          >
            <Form.Label className="text-black font-weight-600 text-primary">
              {t(I18nKey.PHONE_NUMBER_TEXT)}
            </Form.Label>
            <div className="d-flex align-items-center">
              <span className="border border-portage rounded-circle p-2 me-3 bg-white">
                +91
              </span>
              <Form.Control
                required
                type="text"
                placeholder="99876 54321"
                aria-describedby="99876 54321"
                className={`rounded-25 ${
                  isError ? 'border-danger' : 'border-portage'
                } `}
              />
            </div>
          </Form.Group>

          <Form.Group
            as={Col}
            lg={4}
            md={6}
            xs={12}
            className="light-input-wrapper mb-lg-0 mb-4"
          >
            <Form.Label className="text-black font-weight-600 text-primary">
              {t(I18nKey.DOB_TEXT)}
            </Form.Label>

            <Form.Control
              required
              type="date"
              placeholder={t(I18nKey.ENTER_DOB_TEXT)}
              aria-describedby={t(I18nKey.ENTER_DOB_TEXT)}
              className={`rounded-25 ${
                isError ? 'border-danger' : 'border-portage'
              } `}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            lg={4}
            md={6}
            xs={12}
            className="light-input-wrapper mb-lg-0 mb-4"
          >
            <Form.Label className="text-black font-weight-600 text-primary">
              {t(I18nKey.FIRST_NAME_TEXT)}
            </Form.Label>

            <Form.Control
              required
              type="text"
              placeholder={t(I18nKey.ENTER_FIRST_NAME_TEXT)}
              aria-describedby={t(I18nKey.ENTER_FIRST_NAME_TEXT)}
              className={`rounded-25 ${
                isError ? 'border-danger' : 'border-portage'
              } `}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            lg={4}
            md={6}
            xs={12}
            className="light-input-wrapper mb-lg-0 mb-4"
          >
            <Form.Label className="text-black font-weight-600 text-primary">
              {t(I18nKey.LAST_NAME)}
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t(I18nKey.ENTER_SURNAME_TEXT)}
              aria-describedby={t(I18nKey.ENTER_SURNAME_TEXT)}
              className={`rounded-25 ${
                isError ? 'border-danger' : 'border-portage'
              } `}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            lg={4}
            md={6}
            xs={12}
            className="light-input-wrapper mb-lg-0 mb-4"
          >
            <Form.Label className="text-black font-weight-600 text-primary">
              {t(I18nKey.DOB_TEXT)}
            </Form.Label>

            <ButtonGroup
              aria-label={t(I18nKey.GENDER_TEXT)}
              className="form-radio--group"
              onClick={selectGender}
            >
              <Button
                variant={
                  selectedGender === GenderEnum.MALE ? 'portage' : 'light'
                }
                className={`rounded-25 border-portage me-2 ${
                  selectedGender === GenderEnum.MALE
                    ? 'text-white font-weight-600'
                    : 'text-black'
                } `}
                value={GenderEnum.MALE}
              >
                {t(I18nKey.MALE)}
              </Button>
              <Button
                variant={
                  selectedGender === GenderEnum.FEMALE ? 'portage' : 'light'
                }
                className={`rounded-25 border-portage me-2 ${
                  selectedGender === GenderEnum.FEMALE
                    ? 'text-white font-weight-600'
                    : 'text-black'
                } `}
                value={GenderEnum.FEMALE}
              >
                {t(I18nKey.FEMALE)}
              </Button>
              <Button
                variant={
                  selectedGender === GenderEnum.TRANSGENDER
                    ? 'portage'
                    : 'light'
                }
                className={`rounded-25 border-portage me-2 ${
                  selectedGender === GenderEnum.TRANSGENDER
                    ? 'bg- portage text-white font-weight-600'
                    : 'bg-light text-black'
                } `}
                value={GenderEnum.TRANSGENDER}
              >
                {t(I18nKey.TRANSGENDER)}
              </Button>
            </ButtonGroup>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="my-3">
            <Form.Check
              required
              label={t(I18nKey.TERMS_CONDITION_CONTENT)}
              feedback="You must agree before submitting."
              feedbackType="invalid"
            />
            <Form.Text className="text-dimgray mt-2 ms-4 d-block">
              {t(I18nKey.TERMS_CONDITION_NOTE)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="d-flex">
          <Button
            variant="primary"
            type="submit"
            className="rounded-25 px-lg-4 mt-lg-3 mt-2 font-weight-600 mx-lg-0 mx-1"
          >
            {t(I18nKey.PROCEED_TEXT)}
            <img src="/images/right-arrow-white.svg" alt="" className="ms-3" />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RegisterComponent;

import { Button } from 'react-bootstrap';
import { useApp } from '../../../hook/app.hook';
import { memo } from 'react';

const ForwardBackwardComponent = () => {
  const { moveNext, movePrevious } = useApp();

  return (
    <div className="prev--next--wrapper">
      <Button
        onClick={movePrevious}
        variant="link"
        className="p-2 bg-white rounded-circle d-flex align-items-center justify-content-center position-fixed prev--next-btn prev--btn"
      >
        <img src="/images/prev-icon.svg" height="12" alt="" />
      </Button>
      <Button
        onClick={moveNext}
        variant="link"
        className="p-2 bg-white rounded-circle d-flex align-items-center justify-content-center position-fixed prev--next-btn next--btn"
      >
        <img src="/images/next-icon.svg" height="12" alt="" />
      </Button>
    </div>
  );
};

export default memo(ForwardBackwardComponent);

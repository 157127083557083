import { useState } from 'react';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';

const LoginComponent = () => {
  const { openPrepare } = useSSNavigate();
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity()) {
      setIsError(false);
      openPrepare();
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <Col
        lg={5}
        md={12}
        className="text-white px-lg-4 px-5 d-flex flex-column"
      >
        <div className="d-flex flex-column mx-lg-0 mx-auto">
          <h3 className="text-white text-uppercase mb-3">
            {t(I18nKey.STUDENT_LOG_IN)}
          </h3>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label className="text-white">
                  {t(I18nKey.STUDENT_ID)}
                </Form.Label>
                <InputGroup hasValidation className="circle-inputgroup-w">
                  <InputGroup.Text
                    className={`rounded-25 p-1 text-white bg-primary border-end-0 ${
                      isError ? 'border-danger' : 'border-portage'
                    }`}
                  >
                    <img src="/images/circle-user.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder={t(I18nKey.ENTER_YOUR_ID)}
                    aria-describedby={t(I18nKey.ENTER_YOUR_ID)}
                    className={`bg-primary text-white rounded-25 border-start-0 ${
                      isError ? 'border-danger' : 'border-portage'
                    } `}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid student ID
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label className="text-white">
                  {t(I18nKey.PASSWORD_TEXT)}
                </Form.Label>
                <InputGroup hasValidation className="circle-inputgroup-w">
                  <InputGroup.Text
                    className={`rounded-25 p-1 text-white bg-primary border-end-0 ${
                      isError ? 'border-danger' : 'border-portage'
                    }`}
                  >
                    <img src="/images/circle-key.svg" alt="" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="password"
                    placeholder={t(I18nKey.ENTER_SECURITY_PASSCODE)}
                    aria-describedby={t(I18nKey.ENTER_SECURITY_PASSCODE)}
                    className={`bg-primary text-white rounded-25 border-start-0 ${
                      isError ? 'border-danger' : 'border-portage'
                    } `}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid password{' '}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <div className="d-flex">
              <Button
                variant="secondary"
                type="submit"
                className="rounded-25 px-4 mt-3 font-weight-600 mx-lg-0 mx-auto "
              >
                {t(I18nKey.PROCEED_TEXT)}
                <img src="/images/right-arrow.svg" alt="" className="ms-3" />
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default LoginComponent;

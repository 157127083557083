import { useEffect } from 'react';

export const useContextMenuChange = () => {
  const handleContextMenu = (event: Event) => {
    console.log(event);
    event.preventDefault();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    // Check if the key pressed is not a modifier key (Shift, Ctrl, Alt)
    event.preventDefault();
  };

  useEffect(() => {
    // Attach the contextmenu event listener when the component mounts
    document.body.addEventListener('contextmenu', handleContextMenu);

    // Attach the keydown event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

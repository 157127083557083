import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import FooterComponent from '../shared/footer.component';
import GoComponent from './component/go.component';
import HeaderComponent from './component/header.component';
import MenuComponent from './component/menu.component';
import RegisterComponent from './component/register.component';
import SystemCompatibilityComponent from './component/system-compatibility.component';
import { useApp } from '../../hook/app.hook';
import { Section } from '../../response/test.response';
import { useTimer } from '../../hook/timer.hook';

export enum PreTestWindowEnum {
  SYSTEMCOMPATIBILITY = 'System Compatibility',
  REGISTRATION = 'Registration',
  SECTIONQUESTIONLIST = 'Section Quesyion List',
}

const PreparePage = () => {
  const [activeTab, setActiveTab] = useState(
    PreTestWindowEnum.SYSTEMCOMPATIBILITY,
  );
  const [isSystemCompatibility, setIsSystemCompatibility] = useState(true);
  const [isRegistration, setIsRegistration] = useState(false);
  const [isSectionQuesList, setIsSectionQuesList] = useState(false);

  const { testRes } = useApp();
  const { initTimer } = useTimer();

  const handleProceed = (sectionType: PreTestWindowEnum) => {
    setActiveTab(sectionType);
    if (sectionType === PreTestWindowEnum.REGISTRATION) {
      setIsRegistration(true);
      setIsSystemCompatibility(false);
      setIsSectionQuesList(false);
    }
    if (sectionType === PreTestWindowEnum.SECTIONQUESTIONLIST) {
      setIsRegistration(false);
      setIsSystemCompatibility(false);
      setIsSectionQuesList(true);
    }
  };

  useEffect(() => {
    initTimer();
  }, [testRes]);

  return (
    <>
      <section className="mx-xl-5 mx-lg-4 mx-3 d-flex flex-column h-vh-100">
        <div className="mt-lg-5 mt-4 mb-4 py-5 bg-white rounded-25 flex-grow-1">
          <Row className="pb-4 mx-lg-3 mx-1">
            <Col xs={12}>
              <MenuComponent activeTab={activeTab} />
            </Col>
            <Col xs={12}>
              <HeaderComponent
                sectionCount={testRes.sectionList.length}
                questionCount={testRes.sectionList.reduce(
                  (accumulator: number, section: Section) => {
                    return accumulator + section.questionList.length;
                  },
                  0,
                )}
                duration={testRes.duration}
              />
            </Col>
          </Row>
          <Row className="mx-xl-5 mx-lg-4 mx-3">
            <Col
              xs={12}
              className="border-top border-lightgray pt-4 px-xl-5 px-lg-4"
            >
              <div className="mx-xl-5 px-xl-5 mx-lg-3 px-lg-3 py-2">
                {isSystemCompatibility && (
                  <SystemCompatibilityComponent handleProceed={handleProceed} />
                )}

                {isRegistration && (
                  <RegisterComponent handleProceed={handleProceed} />
                )}

                {isSectionQuesList && <GoComponent />}
              </div>
            </Col>
          </Row>
        </div>

        <FooterComponent />
      </section>
    </>
  );
};

export default PreparePage;

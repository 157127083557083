import { useContext } from 'react';
import { AppContext } from '../context/app.context';
import { TimerContext } from '../context/timer.context';

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (context === undefined) {
    throw new Error(`Component is not inside TimerContext scope`);
  }
  return context;
};

import { Row } from 'react-bootstrap';
import CarouselComponent from './component/carousel.component';
import LoginComponent from './component/login.component';
import { useEffect } from 'react';
import { SecurityManager } from '../../security/security-manager';

const AuthPage = () => {
  useEffect(() => {
    SecurityManager.logout();
  }, []);

  return (
    <>
      <Row className="h-vh-100 align-items-center justify-content-center mweb-styles">
        <CarouselComponent />
        <LoginComponent />
      </Row>
    </>
  );
};

export default AuthPage;

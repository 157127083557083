import { Row } from 'react-bootstrap';
import { LoaderView } from '../../view/loader.view';

const HomePage = () => {
  return (
    <>
      <Row className="h-vh-100 align-items-center justify-content-center mweb-styles">
        <LoaderView />
      </Row>
    </>
  );
};

export default HomePage;

import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { Section } from '../../../response/test.response';
import { useApp } from '../../../hook/app.hook';

const GoComponent = () => {
  const { testRes, updateActiveSection, inProgress } = useApp();

  const { openTest } = useSSNavigate();
  const { t } = useTranslation();

  const startTest = () => {
    openTest();
  };

  return (
    <div className="d-flex justify-content-center flex-column">
      <h5 className="ft-16 font-weight-500">
        {t(I18nKey.READY_START_IN_TEXT)}
        <span className="ms-1 font-weight-700">1 min 35 sec</span>
      </h5>
      <div className="text-dimgray">
        {t(I18nKey.SELECT_SECTION_ATTEMPT_START_TEXT)}
      </div>
      <div className="border border-success rounded-25 py-3 px-lg-5 px-3 my-4">
        <Row className="border-bottom border-lightgray text-black pb-2">
          <Col md={4} xs={5} className="text-start text-uppercase px-lg-4 px-2">
            {t(I18nKey.SECTION_NAME_TEXT)}
          </Col>
          <Col
            md={4}
            xs={3}
            className="text-center text-uppercase px-lg-4 px-2"
          >
            <span className="d-lg-block d-none">
              {t(I18nKey.NUMBER_OF_QUESTION_TEXT)}
            </span>
            <span className="d-lg-none d-block">{t(I18nKey.QUES)}</span>
          </Col>
          <Col md={4} xs={4} className="text-end text-uppercase  px-lg-4 px-2">
            <span className="d-lg-block d-none">
              {t(I18nKey.TIME_DURATION_TEXT)}
            </span>
            <span className="d-lg-none d-block">{t(I18nKey.TIME_TEXT)}</span>
          </Col>
        </Row>
        <div className="py-2">
          {testRes.sectionList.map((section: Section, index: number) => {
            return (
              <Row
                id={`section-option-${section.id}`}
                className="py-2 text-dimgray"
              >
                <Col md={4} xs={5} className="px-2">
                  <Form.Check // prettier-ignore
                    type="radio"
                    id={`test-section-${section.id}`}
                    value={section.id}
                    label={section.name.en}
                    name="section"
                    checked={section.active}
                    className="font-weight-600"
                    onChange={(e: any) => {
                      updateActiveSection(e.target.value);
                    }}
                  />
                </Col>
                <Col
                  md={4}
                  xs={3}
                  className="text-center px-lg-4 px-2 font-weight-600"
                >
                  <span>{section.questionList.length}</span>
                </Col>
                <Col
                  md={4}
                  xs={4}
                  className="text-end  px-lg-4 px-2 font-weight-600"
                >
                  <span>{section.maxTime / 60}</span>
                  <span className="ms-2">{t(I18nKey.MINUTES_TEXT)}</span>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>

      <div className="text-dimgray small mb-2">
        {t(I18nKey.UNTIMED_SECTIONS_TIME_LIMIT_INFO)}
      </div>
      <div className="d-flex">
        <Button
          variant="primary"
          type="submit"
          className="rounded-25 px-lg-4 mt-lg-3 mt-2 font-weight-600 mx-lg-0 mx-1"
          onClick={() => {
            startTest();
          }}
        >
          {inProgress ? t(I18nKey.RESUME_TEXT) : t(I18nKey.START_TEXT)}
        </Button>
      </div>
    </div>
  );
};

export default GoComponent;

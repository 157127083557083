import { memo, useEffect, useRef, useState } from 'react';
import { Button, Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useWindow } from '../../../util/window-helper';
import { useApp } from '../../../hook/app.hook';
import { Question, Section } from '../../../response/test.response';

export const sectionFilterConstantType = [
  {
    constantName: 'all',
  },
  {
    constantName: 'attempted',
  },
  {
    constantName: 'revisited',
  },
  {
    constantName: 'unattempted',
  },
];

const FilterByComponent = () => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();
  const [isOpenQuestionFilerList, setIsOpenQuestionFilerList] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState('all');

  const { testRes, updateActiveQuestion } = useApp();

  const boxRef = useRef<any>(null);

  const handleClickOutsideBox = (event: any) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsOpenQuestionFilerList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideBox);

    return () => {
      document.removeEventListener('click', handleClickOutsideBox);
    };
  }, []);

  return (
    <>
      <div ref={boxRef}>
        <Button
          variant="secondary"
          className={`filter-by--style mx-2 ${
            isMobile ? 'border border-levenderblue m--filter-by' : ''
          } `}
          onClick={() => setIsOpenQuestionFilerList(!isOpenQuestionFilerList)}
        >
          <span className="bg-primary"></span>
          <span
            className={isOpenQuestionFilerList ? 'bg-warning' : 'bg-primary'}
          ></span>
          <span
            className={
              isOpenQuestionFilerList ? 'bg-levenderblue' : 'bg-primary'
            }
          ></span>
          <span className="bg-primary"></span>
        </Button>
        {isOpenQuestionFilerList && (
          <div className="filter-by--wrapper rounded" id="box">
            <Card className="border-0 rounded py-2 px-3">
              <Card.Header className="bg-white border-0 d-flex align-items-center mb-2 p-0">
                <span className="font-weight-600 ft-14">
                  {t(I18nKey.FILTER_BY_TEXT)}
                </span>
                <Button
                  variant="link"
                  className="p-0 ms-auto d-flex"
                  onClick={() => setIsOpenQuestionFilerList(false)}
                >
                  <img src="/images/close-red.svg" height="14" alt="" />
                </Button>
              </Card.Header>
              <Card.Body className="bg-white p-0">
                <ButtonGroup className="d-flex">
                  {sectionFilterConstantType.map((filterItem, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`tbRadio-${idx}`}
                      type="radio"
                      variant="outline-levenderblue"
                      className="text-black text-capitalize ft-12 px-1 filters-btn"
                      name="radio"
                      value={filterItem.constantName}
                      checked={selectedFilterType === filterItem.constantName}
                      onChange={(e) =>
                        setSelectedFilterType(e.currentTarget.value)
                      }
                    >
                      {filterItem.constantName}
                    </ToggleButton>
                  ))}
                </ButtonGroup>

                <div className="question--numbering d-flex flex-wrap my-2 py-1">
                  {selectedFilterType === 'all' &&
                    testRes.sectionList
                      .filter((section: Section) => section.active)[0]
                      .questionList.map((question: Question, index: number) => {
                        return (
                          <>
                            {question.markedForRevisit ? (
                              <div
                                onClick={() => {
                                  updateActiveQuestion(question.id);
                                }}
                                className="question--items border border-warning"
                              >
                                {question.index}
                              </div>
                            ) : (
                              <>
                                {question.attempted ? (
                                  <div
                                    onClick={() => {
                                      updateActiveQuestion(question.id);
                                    }}
                                    className="question--items border border-primary"
                                  >
                                    {question.index}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      updateActiveQuestion(question.id);
                                    }}
                                    className="question--items border border-levenderblue"
                                  >
                                    {question.index}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                  {selectedFilterType === 'attempted' &&
                    testRes.sectionList
                      .filter((section: Section) => section.active)[0]
                      .questionList.map((question: Question, index: number) => {
                        return (
                          <>
                            {question.attempted ? (
                              <div
                                onClick={() => {
                                  updateActiveQuestion(question.id);
                                }}
                                className="question--items border border-primary"
                              >
                                {question.index}
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                  {selectedFilterType === 'revisited' &&
                    testRes.sectionList
                      .filter((section: Section) => section.active)[0]
                      .questionList.map((question: Question, index: number) => {
                        return (
                          <>
                            {question.markedForRevisit ? (
                              <div
                                onClick={() => {
                                  updateActiveQuestion(question.id);
                                }}
                                className="question--items border border-warning"
                              >
                                {question.index}
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                  {selectedFilterType === 'unattempted' &&
                    testRes.sectionList
                      .filter((section: Section) => section.active)[0]
                      .questionList.map((question: Question, index: number) => {
                        return (
                          <>
                            {!question.attempted ? (
                              <div
                                onClick={() => {
                                  updateActiveQuestion(question.id);
                                }}
                                className="question--items border border-levenderblue"
                              >
                                {question.index}
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                </div>

                <div className="bg-whisper rounded-25 mb-2 ft-12 d-flex align-items-center justify-content-center px-2 py-1">
                  <div className="px-1 py-1 d-flex align-items-center">
                    <span className="filter--legends bg-primary d-flex rounded-circle me-2"></span>
                    <span>Attempted</span>
                  </div>
                  <div className="px-2 py-1 d-flex align-items-center">
                    <span className="filter--legends bg-warning d-flex rounded-circle me-2"></span>
                    <span>Revisited</span>
                  </div>
                  <div className="px-1 py-1 d-flex align-items-center">
                    <span className="filter--legends bg-levenderblue d-flex rounded-circle me-2"></span>
                    <span>Unattempted</span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(FilterByComponent);

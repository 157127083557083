import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { memo, useEffect, useRef, useState } from 'react';
import { useApp } from '../../../hook/app.hook';
import { secondsToHMS } from '../../../util/timer.util';
import { useUser } from '../../../hook/user.hook';

const HeaderComponent = () => {
  const { t } = useTranslation();
  const { themeType, theme } = useTheme();

  const { lastSaveTime } = useApp();

  const { userRes } = useUser();

  const [lastSaveDiff, setLastSaveDiff] = useState(0);

  const updateResIntervalId = useRef<any>(null);

  const startSaveRes = () => {
    if (updateResIntervalId.current) {
      clearInterval(updateResIntervalId.current);
    }
    updateResIntervalId.current = setInterval(() => {
      const _lastSaveDiff = (new Date().getTime() - lastSaveTime) / 1000;
      setLastSaveDiff(_lastSaveDiff);
    }, 4000);
  };

  useEffect(() => {
    startSaveRes();
    return () => {
      if (updateResIntervalId.current) {
        clearInterval(updateResIntervalId.current);
      }
    };
  }, [lastSaveTime]);

  return (
    <>
      <img
        src="/images/sassy-logo.svg"
        width="115"
        className="mml-14 mmt-8"
        alt=""
      />
      <div
        style={{ color: theme.themewhite }}
        className="d-flex align-items-center border-start border-gainsboro px-2"
      >
        <span style={{ color: theme.themewhite }} className={` mx-2 `}>
          {userRes.userInfo?.name}
        </span>{' '}
        /
        <div style={{ color: theme.themewhite }} className="mx-2">
          <img
            src={`/images/${
              themeType === 'light' ? 'cloud-dark.svg' : 'cloud.svg'
            }`}
            alt=""
          />
          <span className="mx-2">
            {t(I18nKey.SAVED_TEXT)}:{' '}
            <b style={{ color: theme.themewhite }} className="me-1">
              {secondsToHMS(lastSaveDiff)}
            </b>
            {t(I18nKey.AGO_TEXT)}
          </span>
        </div>
      </div>
    </>
  );
};

export default memo(HeaderComponent);

import { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { useWindow } from '../../util/window-helper';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';

const ResultPage = () => {
  const { closeWindow } = useSSNavigate();
  const { t } = useTranslation();
  const { isMobile } = useWindow();
  const [selectedRating, setSelectedRating] = useState(0);

  const handleRatingChange = (newRating: number) => {
    setSelectedRating(newRating);
  };

  const submitRating = () => {
    closeWindow();
  };

  return (
    <>
      <Row className={`m-0 ${isMobile ? 'px-1' : 'h-vh-100'} `}>
        <Col
          lg={6}
          xs={12}
          className={`h-100 ${isMobile ? 'bg-transparent' : 'bg-light'} `}
        >
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <img
              src="/images/result-left.svg"
              height={isMobile ? '200' : '380'}
              alt=""
            />
            <div
              className={`bg-secondary border p-4 border-portage rounded text-center ${
                isMobile ? 'my-2' : 'my-3 '
              } `}
            >
              <h5 className="font-weight-600">
                {t(I18nKey.GREAT_JOB_COMPLETED_TEST_TEXT)}
              </h5>
              <span className="text-dimgray">
                {t(I18nKey.RESPONSE_BEEN_SUBMITTED_TEXT)}
              </span>
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          xs={12}
          className={`d-flex align-items-center justify-content-center flex-column h-100 ${
            isMobile ? 'px-3 mt-2' : 'px-5'
          } `}
        >
          <div
            className={`bg-secondary rounded-25 w-100 ${
              isMobile ? 'px-3 py-2' : 'px-5 py-4 '
            } `}
          >
            <div className="text-center mb-3 pt-3">
              <h6>{t(I18nKey.HOW_TEST_EXPERIENCE_TEXT)}</h6>
              <div className="my-3">
                {Array(5)
                  .fill(null)
                  .map((_, index) => {
                    const i = index + 1;
                    const isFilled = i <= selectedRating;
                    const starIcon = isFilled
                      ? 'solid-star.svg'
                      : 'regular-star.svg';

                    return (
                      <Button
                        variant="secondary"
                        key={`rating_${index}`}
                        className="outline p-0"
                        onClick={() => handleRatingChange(i)}
                      >
                        <img src={`/images/${starIcon}`} alt="" />
                      </Button>
                    );
                  })}
              </div>
              <span className="text-dimgray font-weight-600">
                {t(I18nKey.ANYTHING_LIKE_US_KNOW_TEXT)}
              </span>
            </div>

            <Form.Control
              as="textarea"
              rows={8}
              className="border border-portage rounded"
              placeholder={t(I18nKey.PLEASE_WRITE_FEEDBACK_HERE_TEXT)}
            />
            <ul className="ft-12 text-dimgray mt-3">
              <li className="my-1">
                {t(I18nKey.MINIMUM_CHARACTOR_REQUIRED_FEEDBACK_TEXT)}
              </li>
              <li className="my-1">
                {t(I18nKey.WORD_COUNT_TEXT)}: <b className="ms-1">0/100</b>{' '}
              </li>
            </ul>
            <div className="mt-4 text-center">
              <Button
                onClick={submitRating}
                variant="success"
                className="rounded-25 ft-14 px-3 font-weight-600"
              >
                {t(I18nKey.SHARE_YOUR_FEEDBACK_TEXT)}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResultPage;

import { createContext, useReducer } from 'react';
import { AppState } from '../state/app.state';
import { AppAction } from '../action/app.action';
import { AppReducer } from '../reducer/app.reducer';
import { TestInitData } from '../model/test-init.model';
import {
  Question,
  Section,
  StartTestRes,
  TestRes,
} from '../response/test.response';
import { useTimer } from '../hook/timer.hook';
import { useSaveResponse } from '../page/shared/hook/save-response';
import { CandidateResRes } from '../response/candidate-response.response';

const initialState = {
  loading: false,
  testInitData: {} as TestInitData,
  candidateResponse: {} as CandidateResRes,
  inProgress: false,
  lastSaveTime: new Date().getTime(),
} as AppState;

export const AppContext = createContext<any>(initialState);

export const AppStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const { reSetTimer } = useTimer();

  const { saveTest } = useSaveResponse();

  const initTest = (testId: string) => {
    dispatch({
      type: AppAction.INIT_TEST,
      payload: {
        testInitData: {
          id: testId,
        },
      },
    });
  };

  const updateLoading = (loading: boolean) => {
    dispatch({
      type: AppAction.UPDATE_LOADING,
      payload: { loading },
    });
  };

  const addTestData = (startTestRes: StartTestRes) => {
    dispatch({
      type: AppAction.ADD_TEST_DATA,
      payload: {
        testRes: startTestRes.test,
        candidateResponse: startTestRes.candidateResponse,
        inProgress: startTestRes.inProgress,
      },
    });
  };

  const updateActiveSection = (sectionId: string) => {
    const timeTaken = reSetTimer();
    dispatch({
      type: AppAction.UPDATE_ACTIVE_SECTION,
      payload: { sectionId, timeTaken },
    });
  };

  const updateActiveQuestion = (questionId: string) => {
    const timeTaken = reSetTimer();
    dispatch({
      type: AppAction.UPDATE_ACTIVE_QUESTION,
      payload: { questionId, timeTaken },
    });
  };

  const markQuestionForRevisit = (questionId: string) => {
    dispatch({
      type: AppAction.MARK_QUESTION_REVISIT,
      payload: { questionId },
    });
  };

  const saveQuestionResponse = (
    questionId: string,
    candidateAnswer?: string,
    optionIdList?: any[],
  ) => {
    dispatch({
      type: AppAction.SAVE_QUESTION_RES,
      payload: { questionId, candidateAnswer, optionIdList },
    });
  };

  const clearQuestionResponse = (questionId: string) => {
    dispatch({
      type: AppAction.CLEAR_QUESTION_RES,
      payload: { questionId },
    });
  };

  const moveNext = () => {
    let activeSectionIndex = -1;
    let nextSectionId = '';

    let activeQuestionIndex = -1;
    let nextQuestionId = '';

    const activeSection = state.testRes.sectionList.filter(
      (section: Section, sIndex: number) => {
        if (nextSectionId === '' && activeSectionIndex !== -1) {
          nextSectionId = section.id;
        }
        if (section.active) {
          activeSectionIndex = sIndex;
        }
        return section.active;
      },
    )[0];
    activeSection.questionList.map((question: Question, index: number) => {
      if (nextQuestionId === '' && activeQuestionIndex !== -1) {
        nextQuestionId = question.id;
      }
      if (question.active) {
        activeQuestionIndex = index;
      }
    });

    if (nextQuestionId.length > 0) {
      updateActiveQuestion(nextQuestionId);
    } else if (nextSectionId.length > 0) {
      updateActiveSection(nextSectionId);
    }
  };

  const movePrevious = () => {
    let activeSectionIndex = -1;
    let previousSectionId = '';

    const activeSection = state.testRes.sectionList.filter(
      (section: Section, sIndex: number) => {
        if (section.active) {
          activeSectionIndex = sIndex;
        }
        if (activeSectionIndex === -1) {
          previousSectionId = section.id;
        }
        return section.active;
      },
    )[0];

    let activeQuestionIndex = -1;
    let previousQuestionId = '';

    activeSection.questionList.map((question: Question, index: number) => {
      if (question.active) {
        activeQuestionIndex = index;
      }
      if (activeQuestionIndex === -1) {
        previousQuestionId = question.id;
      }
    });

    if (previousQuestionId.length > 0) {
      updateActiveQuestion(previousQuestionId);
    } else if (previousSectionId.length > 0) {
      updateActiveSection(previousSectionId);
    }
  };

  const moveNextQuestion = () => {
    let activeQuestionIndex = -1;
    let nextQuestionId = '';

    const activeSection = state.testRes.sectionList.filter(
      (section: Section, sIndex: number) => {
        return section.active;
      },
    )[0];
    activeSection.questionList.map((question: Question, index: number) => {
      if (nextQuestionId === '' && activeQuestionIndex !== -1) {
        nextQuestionId = question.id;
      }
      if (question.active) {
        activeQuestionIndex = index;
      }
    });

    if (nextQuestionId.length > 0) {
      updateActiveQuestion(nextQuestionId);
    }
  };

  const movePreviousQuestion = () => {
    const activeSection = state.testRes.sectionList.filter(
      (section: Section, sIndex: number) => {
        return section.active;
      },
    )[0];

    let activeQuestionIndex = -1;
    let previousQuestionId = '';

    activeSection.questionList.map((question: Question, index: number) => {
      if (question.active) {
        activeQuestionIndex = index;
      }
      if (activeQuestionIndex === -1) {
        previousQuestionId = question.id;
      }
    });

    if (previousQuestionId.length > 0) {
      updateActiveQuestion(previousQuestionId);
    }
  };

  const updateLastSaveTime = (lastSaveTime: number) => {
    dispatch({
      type: AppAction.UPDATE_LAST_SAVE_TIME,
      payload: { lastSaveTime },
    });
  };

  const saveResponse = async (
    testRes: TestRes,
    closed: boolean,
    handleClose?: () => void,
  ) => {
    if (closed) {
      updateLoading(true);
    }
    const [data, err] = await saveTest(testRes, closed);

    if (!err) {
      if (closed && handleClose) {
        handleClose();
      }
      updateLastSaveTime(new Date().getTime());
    } else {
      if (closed) {
        saveResponse(testRes, closed);
      }
    }
  };

  const value = {
    loading: state.loading,
    testInitData: state.testInitData,
    testRes: state.testRes,
    lastSaveTime: state.lastSaveTime,
    inProgress: state.inProgress,
    initTest,
    addTestData,
    updateLoading,
    updateActiveQuestion,
    updateActiveSection,
    markQuestionForRevisit,
    saveQuestionResponse,
    clearQuestionResponse,
    moveNext,
    movePrevious,
    moveNextQuestion,
    movePreviousQuestion,
    saveResponse,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { useTheme } from '../../themes/theme.provider';

const FooterComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className="d-lg-flex d-none mt-auto pb-4">
      <div className="pe-2">
        <div
          style={{ color: theme.themewhite }}
          className="d-flex align-items-center"
        >
          {t(I18nKey.COPYRIGHT_ASSESSMENT_DATA)}
          <span className="ms-1">
            <img src="/images/wi-fi.svg" alt="" />
          </span>
        </div>
      </div>
      <div className="ms-auto pe-2">
        <div
          style={{ color: theme.themewhite }}
          className="d-flex align-items-center"
        >
          {t(I18nKey.NEED_CONTACT_TEXT)}
          <span className="font-weight-600 ms-1">+91 99876 54321</span>
        </div>
      </div>
      <div className="ms-auto">
        <div
          style={{ color: theme.themewhite }}
          className="d-flex align-items-center"
        >
          {t(I18nKey.POWERED_BY_TEXT)}
          <span className="ms-1">
            <img src="/images/sassy-logo.svg" alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;

import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { useEffect, useState } from 'react';
import { useApp } from '../../../hook/app.hook';
import { TestService } from '../../../service/test.service';

export const useInitTest = () => {
  const { openNotFound } = useSSNavigate();
  const { testInitData, addTestData } = useApp();

  const [loading, setLoading] = useState(true);

  const fetchTest = async () => {
    const [data] = await TestService.startTestById(testInitData.id);
    if (data) {
      addTestData(data);
    } else {
      openNotFound();
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchTest();
  }, []);

  return {
    loading,
  };
};

import React, { memo, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useFullScreen } from '../../../full-screen/full-screen.hook';
import { useTheme } from '../../../themes/theme.provider';

const FullScreenComponent = () => {
  const { fullScreen, setFullScreen } = useFullScreen();
  const { themeType } = useTheme();

  const toggleFullScreen = () => {
    if (fullScreen === 0 || fullScreen === -1) {
      setFullScreen(1);
    } else {
      setFullScreen(-1);
    }
  };

  useEffect(() => {
    setFullScreen(1);
  }, []);

  return (
    <Button
      variant="link"
      className="text-white me-3 p-0"
      onClick={toggleFullScreen}
    >
      <img
        src={`/images/${
          themeType === 'light' ? 'screen-dark.svg' : 'screen.svg'
        } `}
        alt=""
      />
    </Button>
  );
};

export default memo(FullScreenComponent);

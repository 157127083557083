import { memo } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTheme } from '../../../themes/theme.provider';
import { useApp } from '../../../hook/app.hook';
import { Question, Section } from '../../../response/test.response';
import { useWindow } from '../../../util/window-helper';

const QuestionPaginationComponent = () => {
  const { theme } = useTheme();
  const { isMobile } = useWindow();

  const {
    testRes,
    updateActiveQuestion,
    moveNextQuestion,
    movePreviousQuestion,
  } = useApp();

  const section = testRes.sectionList.filter(
    (section: Section) => section.active,
  )[0];

  const questionNumberStyle = {
    background: theme.primary,
    color: theme.themewhite,
    borderColor: theme.themewhite,
  };

  const handleQuestionChange = (question: Question) => {
    updateActiveQuestion(question.id);
  };

  const activeQuestionIndex = section.questionList.filter(
    (question: Question) => question.active,
  )[0].index;

  const breakPoint = isMobile ? 7 : 9;
  const sideLength = isMobile ? 4 : 5;

  let startPosition = Math.max(
    0,
    activeQuestionIndex - sideLength >= 0
      ? activeQuestionIndex - sideLength
      : 0,
  );
  const endPosition = Math.min(
    section.questionList.length,
    startPosition + breakPoint,
  );

  if (
    startPosition - endPosition < breakPoint &&
    section.questionList.length >= breakPoint
  ) {
    startPosition = endPosition - breakPoint;
  }

  return (
    <>
      <Pagination className="m-0 test-pagination">
        <Pagination.Prev
          onClick={movePreviousQuestion}
          disabled={activeQuestionIndex === 1}
          className="prev-next-item mx-1"
        >
          <img src="/images/prev-icon.svg" alt="" height="9" />
        </Pagination.Prev>
        {section.questionList
          .slice(startPosition, endPosition)
          .map((question: Question) => {
            return (
              <Pagination.Item
                key={question.id}
                active={question.active}
                onClick={() => handleQuestionChange(question)}
                style={questionNumberStyle}
                className="mx-1"
              >
                {question.index}
              </Pagination.Item>
            );
          })}
        <Pagination.Next
          onClick={moveNextQuestion}
          disabled={activeQuestionIndex === section.questionList.length}
          className="prev-next-item mx-1"
        >
          <img src="/images/next-icon.svg" alt="" height="9" />
        </Pagination.Next>
      </Pagination>
    </>
  );
};

export default memo(QuestionPaginationComponent);

import { memo, useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFont } from '../../../font/font.hook';
import { FontSize } from '../../../font/font.type';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { ThemeType } from '../../../themes/theme.type';

export enum ThemeEnum {
  LIGHT = 'light',
  MONO = 'mono',
  DARK = 'dark',
}

export const themeTypeArray = ['light', 'mono', 'dark'];
export const fontSizeType = [FontSize.SMALL, FontSize.NORMAL, FontSize.LARGE];

export const ThemeFontSizeSettings = () => {
  const { t } = useTranslation();
  const { fontSize, setFontSize } = useFont();
  const { themeType, theme, setTheme } = useTheme();
  let doc = document as any;

  const handleFontSizeUpdate = (ftSize: string) => {
    if (ftSize === FontSize.LARGE) {
      setFontSize(FontSize.LARGE);
      doc.querySelector('body').classList.remove('small-font');
      doc.querySelector('body').classList.add('large-font');
    } else if (ftSize === FontSize.NORMAL) {
      setFontSize(FontSize.NORMAL);
      doc.querySelector('body').classList.remove('large-font');
      doc.querySelector('body').classList.remove('small-font');
    } else {
      setFontSize(FontSize.SMALL);
      doc.querySelector('body').classList.remove('large-font');
      doc.querySelector('body').classList.add('small-font');
    }
  };

  const handleThemeUpdate = (themeType: string) => {
    if (themeType === ThemeType.LIGHT) {
      setTheme(ThemeType.LIGHT);
      doc.querySelector('body').classList.remove('dark-theme');
      doc.querySelector('body').classList.remove('mono-theme');
      doc.querySelector('body').classList.add('light-theme');
    } else if (themeType === ThemeType.DARK) {
      setTheme(ThemeType.DARK);
      doc.querySelector('body').classList.remove('light-theme');
      doc.querySelector('body').classList.remove('mono-theme');
      doc.querySelector('body').classList.add('dark-theme');
    } else {
      setTheme(ThemeType.MONO);
      doc.querySelector('body').classList.remove('dark-theme');
      doc.querySelector('body').classList.remove('light-theme');
      doc.querySelector('body').classList.add('mono-theme');
    }
  };

  useEffect(() => {
    handleFontSizeUpdate(FontSize.NORMAL);
  }, []);

  return (
    <>
      <div className="pb-3">
        <div className="ft-12 text-dimgray font-weight-700 mb-1">
          {t(I18nKey.CHANGE_THEME_TEXT)}
        </div>
        <ButtonGroup className="d-flex">
          {themeTypeArray.map((themeItem: any, idx) => (
            <ToggleButton
              key={idx}
              id={`theme_type_${idx}`}
              type="radio"
              title={themeItem}
              variant="outline-levenderblue"
              className={`text-capitalize ft-12-px px-1 ${
                themeType === themeItem
                  ? 'bg-primary text-white'
                  : ' text-black bg-white'
              }`}
              name="radio"
              value={themeItem}
              checked={themeType === themeItem}
              onChange={(e) => handleThemeUpdate(e.currentTarget.value)}
            >
              {themeItem === ThemeEnum.LIGHT && (
                <img
                  src={`/images/${
                    themeType === themeItem
                      ? 'light-theme-white.svg'
                      : 'light-theme.svg'
                  }`}
                  alt=""
                />
              )}
              {themeItem === ThemeEnum.DARK && (
                <img
                  src={`/images/${
                    themeType === themeItem
                      ? 'dark-theme-white.svg'
                      : 'dark-theme.svg'
                  }`}
                  alt=""
                />
              )}
              {themeItem === ThemeEnum.MONO && (
                <img
                  src={`/images/${
                    themeType === themeItem
                      ? 'mono-theme-white.svg'
                      : 'mono-theme.svg'
                  }`}
                  alt=""
                />
              )}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
      <div className="pb-3">
        <div className="ft-12 text-dimgray font-weight-700 mb-1">
          {t(I18nKey.CHANGE_FONTSIZE_TEXT)}
        </div>
        <ButtonGroup className="d-flex">
          {fontSizeType.map((fontSizeItem, idx) => (
            <ToggleButton
              key={idx}
              id={`font_size_${idx}`}
              type="radio"
              title={fontSizeItem}
              variant="outline-levenderblue"
              className={`text-capitalize ft-12-px px-1 d-flex justify-content-center align-items-center ${
                fontSize === fontSizeItem
                  ? 'bg-primary text-white'
                  : ' text-black bg-white'
              }`}
              name="radio"
              value={fontSize}
              checked={fontSize === fontSizeItem}
              onChange={() => handleFontSizeUpdate(fontSizeItem)}
            >
              {fontSizeItem === FontSize.SMALL && (
                <span className="ft-12-px font-weight-600">Aa</span>
              )}
              {fontSizeItem === FontSize.NORMAL && (
                <span className="ft-14-px font-weight-600">Aa</span>
              )}
              {fontSizeItem === FontSize.LARGE && (
                <span className="ft-18-px font-weight-600">Aa</span>
              )}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    </>
  );
};

const SettingComponent = () => {
  const { t } = useTranslation();
  const { themeType } = useTheme();

  const [isOpenSettingDropDown, setIsOpenSettingDropDown] = useState(false);

  const handleSettingToggle = (isOpen: boolean) => {
    setIsOpenSettingDropDown(isOpen);
  };

  return (
    <>
      <Dropdown
        show={isOpenSettingDropDown}
        onToggle={handleSettingToggle}
        className="setting-dropdown"
      >
        <Dropdown.Toggle
          variant="link"
          id="settings-basic"
          className="text-white me-3 p-0"
        >
          <img
            src={`/images/${
              themeType === 'light' ? 'settings-dark.svg' : 'settings.svg'
            } `}
            alt=""
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="bg-white rounded border-0 p-3 shadow">
          <div className="d-flex align-items-center justify-content-cneter mb-3">
            <h5 className="ft-18 font-weight-600 m-0">Settings</h5>
            <Button
              variant="link"
              className="text-white ms-auto p-0"
              onClick={() => handleSettingToggle(false)}
            >
              <img src="/images/close-red.svg" height="18" alt="" />
            </Button>
          </div>
          <ThemeFontSizeSettings />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default memo(SettingComponent);

import { Form } from 'react-bootstrap';
import { CKTextView } from '../../../../view/ck-text.view';
import { Option, Question, Section } from '../../../../response/test.response';
import { useApp } from '../../../../hook/app.hook';

const MultipleChoiceComponent = () => {
  const { testRes, saveQuestionResponse } = useApp();

  const question = testRes.sectionList
    .filter((section: Section) => section.active)[0]
    .questionList.filter((question: Question, index: number) => {
      return question.active;
    })[0];

  return (
    <div>
      {question.optionList.map((option: Option) => {
        return (
          <Form.Check
            type="checkbox"
            id={`question-${question.id}-${option.id}`}
            label={<CKTextView initData={option.value.en} />}
            name="mcq"
            value={option.id}
            defaultChecked={option.selected}
            checked={option.selected}
            className="mb-3 bg-white d-flex align-items-center p-0 mcq-inputs"
            onChange={(e: any) => {
              const newValue = e.target.value;
              const optionIdList = question.optionList
                .filter((option: Option) => {
                  return (
                    (option.id == newValue && !option.selected) ||
                    (option.id != newValue && option.selected)
                  );
                })
                .map((option: Option) => {
                  return option.id;
                });
              saveQuestionResponse(question.id, null, optionIdList);
            }}
          />
        );
      })}
    </div>
  );
};

export default MultipleChoiceComponent;

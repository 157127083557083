import { AuthTokenReq, LogOutReq } from '../request/auth.request';
import { AuthTokenRes } from '../response/auth.response';
import { SecurityManager } from '../security/security-manager';
import httpClient from '../util/http-client';

export const AuthService = {
  getAuthToken: async (
    testId?: string | null,
    userId?: string | null,
    accessToken?: string | null,
  ): Promise<(AuthTokenRes | any)[]> => {
    let data;
    let err;
    try {
      const authTokenReq = {
        testId: testId ? testId : SecurityManager.getTestId(),
        userId: userId ? userId : SecurityManager.getUid(),
        accessToken: accessToken
          ? accessToken
          : SecurityManager.getAccessToken(),
      } as AuthTokenReq;
      const res = await httpClient.post('/test/auth-token', authTokenReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  logOut: async (): Promise<any[]> => {
    let data;
    let err;
    try {
      const authTokenReq = {
        userId: SecurityManager.getUid(),
        testToken: SecurityManager.getAccessToken(),
        authToken: SecurityManager.getAuthToken(),
      } as LogOutReq;
      const res = await httpClient.post('/log-out', authTokenReq);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};

import { useSearchParams } from 'react-router-dom';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { SecurityManager } from '../../../security/security-manager';
import { AuthService } from '../../../service/auth.service';
import { Session } from '../../../model/session.model';
import { useEffect, useState } from 'react';
import { useApp } from '../../../hook/app.hook';

export const useValidateTest = () => {
  const { openAuth, openPrepare, openNotFound } = useSSNavigate();
  const { initTest } = useApp();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const validateTest = async () => {
    const testId = searchParams.get('testId');
    const token = searchParams.get('token');
    const userId = searchParams.get('userId');

    if (
      testId &&
      testId.length > 0 &&
      userId &&
      userId.length > 0 &&
      token &&
      token.length > 0
    ) {
      initTest(testId);
    } else {
      return;
    }

    const [data, err] = await AuthService.getAuthToken(testId, userId, token);
    setLoading(false);
    if (data && data.authToken && data.authToken.length > 0) {
      SecurityManager.setSession({
        testId: testId,
        userId: userId,
        authToken: data.authToken,
        accessToken: token,
      } as Session);
      openPrepare();
    } else {
      if (false) {
        openAuth();
      } else {
        SecurityManager.logout();
        openNotFound();
      }
    }
  };

  useEffect(() => {
    validateTest();
  }, []);

  return {
    loading,
  };
};

import { useContextMenuChange } from './context-menu';
import { useTabChange } from './tab-change';
import { useWindowChange } from './window-change';

export const useInvigilate = () => {
  useContextMenuChange();
  const { windowMoveCount, fullScreenCloseCount } = useWindowChange();
  const { tabMoveCount } = useTabChange();

  return {
    windowMoveCount: windowMoveCount,
    tabMoveCount: tabMoveCount,
    fullScreenCloseCount: fullScreenCloseCount,
  };
};

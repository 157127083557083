import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import { useTimer } from '../../../hook/timer.hook';
import { secondsToHMS } from '../../../util/timer.util';
import { memo, useEffect, useRef, useState } from 'react';
import { TimerStage } from '../../../enum/timer-stage.enum';
import { useApp } from '../../../hook/app.hook';
import { Question, Section } from '../../../response/test.response';
import { useInvigilate } from '../../../invigilate/invigilate.hook';
import { Modal, Button } from 'react-bootstrap';
import { FALSE } from 'sass';

const TimerComponent = () => {
  const { isMobile } = useWindow();
  const { t } = useTranslation();
  const { theme, themeType } = useTheme();
  const [timeTaken, setTimeTaken] = useState(0);

  const { testRes, saveResponse } = useApp();
  const { stage, startTime, startTimer } = useTimer();

  const { fullScreenCloseCount, tabMoveCount } = useInvigilate();

  const intervalId = useRef<any>(null);

  const updateResIntervalId = useRef<any>(null);

  const checkForExpiry = (timeTaken: number) => {
    if (timeTaken > testRes.duration) {
      saveTest(false);
    }
  };

  const startTicker = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    intervalId.current = setInterval(() => {
      if (stage == TimerStage.Started) {
        const currentTime = new Date().getTime();
        startTimeRef.current = startTime;
        const _timeTaken =
          (testRes.timeTaken ? testRes.timeTaken : 0) +
          (currentTime - startTime) / 1000;
        setTimeTaken(_timeTaken);
        checkForExpiry(_timeTaken);
      }
    }, 1000);
  };

  useEffect(() => {
    if (stage == TimerStage.Started) {
      startTicker();
    } else if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, [stage]);

  useEffect(() => {
    startTimer();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  const startTimeRef = useRef<any>(startTime);
  const testResRef = useRef<any>(testRes);

  const saveTest = (closed: boolean) => {
    const currentTime = new Date().getTime();
    const _timeTaken = (currentTime - startTimeRef.current) / 1000;

    const _testRes = testResRef.current;

    const __testRes = {
      ..._testRes,
      timeTaken: _testRes.timeTaken + _timeTaken,
      sectionList: _testRes.sectionList.map((section: Section) => {
        return {
          ...section,
          ...(section.active && {
            timeTaken: section.timeTaken + _timeTaken,
            questionList: section.questionList.map((question: Question) => {
              return {
                ...question,
                ...(question.active && {
                  timeTaken: question.timeTaken + _timeTaken,
                }),
              };
            }),
          }),
        };
      }),
    };

    saveResponse(__testRes, closed);
  };

  const startSaveRes = () => {
    if (updateResIntervalId.current) {
      clearInterval(updateResIntervalId.current);
    }
    updateResIntervalId.current = setInterval(() => {
      saveTest(false);
    }, 20000);
  };

  useEffect(() => {
    startSaveRes();
    return () => {
      if (updateResIntervalId.current) {
        clearInterval(updateResIntervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    testResRef.current = testRes;
  }, [testRes]);

  useEffect(() => {
    if (tabMoveCount > 0 || fullScreenCloseCount > 0) {
      setShow(true);
    }
  }, [tabMoveCount, fullScreenCloseCount]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      <div
        style={{ color: theme.themewhite, minWidth: 204, textAlign: 'left' }}
        className={`${isMobile ? 'ms-auto' : 'mx-3'}`}
      >
        <img
          src={`/images/${
            themeType === 'light' ? 'timer-dark.svg' : 'timer.svg'
          }`}
          alt=""
        />
        <span className="mx-2">
          {t(I18nKey.TEST_TIME)}: {secondsToHMS(testRes.duration - timeTaken)}
        </span>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={false}>
          <p className="text-danger">Instruction Violation</p>
        </Modal.Header>
        <Modal.Body>
          Please do not change tab or window Size, Next time we will terminate
          your test!
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(TimerComponent);

import { useContext } from 'react';
import { AppContext } from '../context/app.context';

export const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(`Component is not inside AppContext scope`);
  }
  return context;
};

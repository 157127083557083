import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';

export interface HeaderComponentProps {
  sectionCount: number;
  questionCount: number;
  duration: number;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  sectionCount,
  questionCount,
  duration,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="w-45 mt-lg-4 mt-1 align-items-center justify-content-center mx-lg-auto mx-0">
        <Col
          lg={4}
          xs={12}
          className="d-flex py-2 text-center justify-content-center"
        >
          <div className="ms-lg-2 m-0">
            <span className="d-block text-blue-gray-300 text-dimgray">
              {t(I18nKey.QUESTION_COUNT_TEXT)}
            </span>
            <div className="font-weight-600 text-white mt-2 info-wrapper ft-16 text-black">
              <span>{questionCount}</span>
              <span className="ms-2">{t(I18nKey.QUESTION_TEXT)}</span>
            </div>
          </div>

          <div className="v-divider ms-auto d-lg-block d-none"></div>
        </Col>
        <Col
          lg={4}
          xs={12}
          className="d-flex py-2 text-center justify-content-center"
        >
          <div className="mx-lg-auto mx-0">
            <span className="d-block text-blue-gray-300 text-dimgray">
              {t(I18nKey.SECTION_COUNT_TEXT)}
            </span>
            <div className="font-weight-600 text-white mt-2 info-wrapper ft-16 text-black">
              <span>{sectionCount}</span>
              <span className="ms-2">{t(I18nKey.SECTION_TEXT)}</span>
            </div>
          </div>
          <div className="v-divider ms-auto d-lg-block d-none"></div>
        </Col>
        <Col
          lg={4}
          xs={12}
          className="d-flex py-2 text-center justify-content-center"
        >
          <div className="mx-lg-auto mx-0">
            <span className="d-block text-blue-gray-300 text-dimgray">
              {t(I18nKey.TEST_DURATION_TEXT)}
            </span>
            <div className="font-weight-600 text-white mt-2 info-wrapper ft-16 text-black">
              <span>{duration / 60}</span>
              <span className="ms-2">{t(I18nKey.MINUTES_TEXT)}</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default HeaderComponent;

import { Button } from 'react-bootstrap';
import { useApp } from '../../../hook/app.hook';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTranslation } from 'react-i18next';
import { useWindow } from '../../../util/window-helper';
import { memo, useEffect, useState } from 'react';
import { Question, Section } from '../../../response/test.response';

const QuestionInfoComponent = () => {
  const { t } = useTranslation();
  const { testRes } = useApp();

  const { isMobile } = useWindow();

  const [totalCount, setTotalCount] = useState(0);
  const [attemptedCount, setAttemptedCount] = useState(0);

  useEffect(() => {
    let _totalCount = 0;
    let _attemptedCount = 0;
    testRes.sectionList.map((section: Section) => {
      section.questionList.map((question: Question) => {
        _totalCount++;
        if (question.attempted) {
          _attemptedCount++;
        }
      });
    });

    setTotalCount(_totalCount);
    setAttemptedCount(_attemptedCount);
  }, [testRes]);

  return (
    <div className={`text-center ${isMobile ? 'my-3 px-3' : 'ms-5'}`}>
      <span>{t(I18nKey.ATTEMPTED_TEXT)}:</span>
      <span className="font-weight-600 ms-2">
        {attemptedCount}/{totalCount}
      </span>
    </div>
  );
};

export default memo(QuestionInfoComponent);

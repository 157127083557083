import { UserAction } from '../action/user.action';
import { Action } from '../model/action.model';
import { UserState } from '../state/user.state';

export const UserReducer = (state: UserState, action: Action): UserState => {
  const { type, payload } = action;

  switch (type) {
    case UserAction.SET_USER:
      return {
        ...state,
        userRes: payload.userRes,
      };
    default:
      throw new Error(`No case for type ${type} found in UserReducer.`);
  }
};

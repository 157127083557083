export enum I18nKey {
  APP_NAME = 'app_name',
  STUDENT_LOG_IN = 'student_log_in',
  PROCEED_TEXT = 'proceed_text',
  STUDENT_ID = 'studend_id',
  ENTER_YOUR_ID = 'enter_your_id',
  PASSWORD_TEXT = 'password_text',
  ENTER_SECURITY_PASSCODE = 'enter_security_passcode',
  HI_TEXT = 'hi_text',
  WELCOME_TEXT = 'welcome_text',
  HELPLINE_NO_TEXT = 'helpline_no_text',
  SLIDE_ONE_TEXT = 'slide_one_text',
  SLIDE_TWO_TEXT = 'slide_two_text',
  SLIDE_THREE_TEXT = 'slide_three_text',
  READY_TEXT = 'ready_text',
  SET_TEXT = 'set_text',
  GO_TEXT = 'go_text',
  QUESTION_COUNT_TEXT = 'question_count_text',
  SECTION_COUNT_TEXT = 'section_count_text',
  TEST_DURATION_TEXT = 'test_duration',
  QUESTION_TEXT = 'quesitons_text',
  SECTION_TEXT = 'section_text',
  MINUTES_TEXT = 'minutes_text',
  ALL_SET_PROCEED_TEXT = 'all_set_proceed_text',
  SYSTEM_COMPATIBILITY_TEXT = 'system_compatibity_text',
  GRAMMER_SPELL_PLUGIN_CONTENT = 'grammer_spell_plugin_content',
  COPYRIGHT_ASSESSMENT_DATA = 'copyright_assessment_data',
  NEED_CONTACT_TEXT = 'need_contact_text',
  POWERED_BY_TEXT = 'powred_by_text',
  REGISTRATION_HEADING = 'registration_detail_heading',
  EMAIL_ADDRESS_TEXT = 'email_address_text',
  ENTER_EMAIL_ID_TEXT = 'enter_email_id_text',
  PHONE_NUMBER_TEXT = 'phone_number_text',
  DOB_TEXT = 'dob_text',
  ENTER_DOB_TEXT = 'enter_date_of_birth_text',
  FIRST_NAME_TEXT = 'first_name_text',
  ENTER_FIRST_NAME_TEXT = 'enter_first_name_text',
  LAST_NAME = 'last_name',
  ENTER_SURNAME_TEXT = 'enter_surname_text',
  GENDER_TEXT = 'gender_text',
  MALE = 'male',
  FEMALE = 'female',
  TRANSGENDER = 'Transgender',
  TERMS_CONDITION_CONTENT = 'terms_condition_content',
  TERMS_CONDITION_NOTE = 'terms_conditions_note',
  READY_START_IN_TEXT = 'ready_start_in_text',
  SELECT_SECTION_ATTEMPT_START_TEXT = 'select_section_attempt_start_text',
  SECTION_NAME_TEXT = 'section_name_text',
  NUMBER_OF_QUESTION_TEXT = 'number_of_question_text',
  TIME_DURATION_TEXT = 'time_duration_text',
  UNTIMED_SECTIONS_TIME_LIMIT_INFO = 'untimed_sections_time_limit_info',
  START_TEXT = 'start_text',
  RESUME_TEXT = 'resume_text',
  QUES = 'ques',
  TIME_TEXT = 'time_text',
  SAVED_TEXT = 'saved_text',
  AGO_TEXT = 'ago_text',
  TEST_TIME = 'test_time',
  FILTER_BY_TEXT = 'filter_by_text',
  ALL = 'all',
  ATTEMPTED = 'attempted',
  REVISITED = 'revisited',
  UNATTEMPTED = 'unattempted',
  REMAINING_TIME_TEXT = 'remaining_time_text',
  CHANGE_THEME_TEXT = 'change_theme_text',
  CHANGE_FONTSIZE_TEXT = 'change_font_size_text',
  ATTEMPTED_TEXT = 'attempted_text',
  PREVIOUS_TEXT = 'previous_text',
  NEXT_TEXT = 'next_text',
  TEST_QUESTION_TEXT = 'question_text',
  ENTER_YOUR_RESPONSE_TEXT = 'enter_your_repponse_text',
  CLEAR_RESPONSE_TEXT = 'clear_response_text',
  REVIST_LATTER_TEXT = 'revist_latter_text',
  POSITIVE_MARKING_TEXT = 'positive_marking_text',
  NEGATIVE_MARKETING_TEXT = 'negative_marking_text',
  GENERAL_INSTRUCTIONS_TEXT = 'general_instrucitons_text',
  TEST_INSTRUCTIONS_TEXT = 'test_instrucitons_text',
  END_TEXT = 'end_text',
  FINISH_TEXT = 'finish_text',
  YOUR_TEST_SUMMARY_TEXT = 'your_text_summary_text',
  SECTION_SUMMARY_TEXT = 'section_summary_text',
  STATUS_TEXT = 'status_text',
  TOTAL_TEXT = 'total_text',
  YES_END_TEXT = 'yes_end_text',
  NP_BACK_TO_TEST_TEXT = 'no_back_to_text',
  TOTAL_QUESTION_KEY = 'total_question_text',
  MARKED_FOR_VISITED = 'marked_for_visited',
  GREAT_JOB_COMPLETED_TEST_TEXT = 'great_job_completed_test_text',
  RESPONSE_BEEN_SUBMITTED_TEXT = 'response_beed_submitted_text',
  HOW_TEST_EXPERIENCE_TEXT = 'how_test_experience_text',
  ANYTHING_LIKE_US_KNOW_TEXT = 'anything_like_us_know_text',
  PLEASE_WRITE_FEEDBACK_HERE_TEXT = 'please_write_feedback_here_text',
  MINIMUM_CHARACTOR_REQUIRED_FEEDBACK_TEXT = 'minimum_charactor_required_feedback_text',
  WORD_COUNT_TEXT = 'word_count_text',
  SHARE_YOUR_FEEDBACK_TEXT = 'share_your_feedback_text',
  INSTRUCTIONS_TEXT = 'instruction_text',
  GOOD_CONNECTION_TEXT = 'good_connection_text',
  RESPONSE_SAVED_TEXT = 'response_saved_text',
  NEED_HELP_ONLY_TEXT = 'need_help_only_text',
  CONTACT_US_TEXT = 'contact_us_text',
}
